@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300@400;500;700&display=swap");

:root {
  --primaryClr: #ffbd00;
  --secondaryClr: #222a34;
  --headClr: #101520;
  --bodyClr: #1a1e29;
  --whiteClr: #ffffff;
  --blackClr: #000000;
  --lightClr: rgba(255, 255, 255, 0.6);
  --siteRedClr: #af2f2f;
}
.light:root {
  --primaryClr: #ffbd00;
  --secondaryClr: #999;
  --headClr: rgba(0, 0, 0, 0.175);
  --bodyClr: #ddd;
  --whiteClr: #000000;
  --blackClr: #ffffff;
}

img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 1250px) {
  .welcome-offer {
    min-height: 6000px !important;
  }
}

@media screen and (max-width: 767.98px) {
  .BlogSection ul li {
    width: 100% !important;
    min-height: inherit !important;
    margin-bottom: 12px !important;
  }
  .BlogSection ul li a .BlogListing .BlogListText h2 {
    font-size: 14px;
  }
  .BlogSection ul li a .BlogListing .BlogListText p {
    font-size: 10px;
    margin-top: 5px !important;
  }
  .BlogContent .BlogText h1 {
    font-size: 22px !important;
  }
  .BlogContent .BlogText h2 {
    font-size: 20px !important;
  }
  .BlogContent .BlogText h3 {
    font-size: 18px !important;
  }
  .BlogSection ul li a .BlogListing .BlogListText h2 {
    font-size: 14px !important;
  }
  .BlogSection ul li a .BlogListing .BlogListText p {
    font-size: 10px !important;
    margin-top: 5px !important;
  }

  .main .PageSection .ProfileBarHead {
    margin-bottom: 0 !important;
  }
  .main .PageSection .ProfileBarHead h2 {
    font-size: 17px;
  }
  .main .PageSection .PageRightBar .ProfileMainSec {
    gap: 0 !important;
  }
  .main .PageSection .PageRightBar .ProfileMainSec .reset-password {
    padding: 0 !important;
  }
  .main .PageSection .PageRightBar .ProfileMainSec .reset-password button {
    background: transparent !important;
    border: 0 !important;
    color: var(--primaryClr) !important;
    font-size: 16px;
    padding: 0 !important;
  }
  .main .PageSection .PageRightBar .ProfileMainSec .ProfileImgSec,
  .main .PageSection .PageRightBar .ProfileMainSec .ProfileForm {
    border-radius: 0 !important;
  }
  .main .PageSection .PageRightBar .ProfileMainSec .reset-password {
    border-top: 0 !important;
  }
  body .welcome-offer.afterloginwelcome {
    min-height: 580px !important;
  }
  .welcome-offer .WelcomeContentSection {
    top: 26% !important;
    left: 4% !important;
    width: 100%;
  }
  .welcome-offer .WelcomeContentSection .WelcomeForm {
    width: 100% !important;
  }
  .welcome-offer .WelcomeMobileImg {
    margin-top: 0 !important;
    width: 100% !important;
    justify-content: center !important;
  }
  .welcome-offer .WelcomeMobileImg img {
    width: 350px !important;
  }
  .welcome-offer .WelcomeContentSection .welcomeContent h3 {
    font-size: 12px;
  }
  .welcome-offer .WelcomeContentSection .welcomeContent h4,
  .welcome-offer .WelcomeContentSection .WelcomeForm p {
    font-size: 18px !important;
  }
  .welcome-offer .WelcomeContentSection .WelcomeForm input {
    width: 100%;
  }
  .welcome-offer .WelcomeContentSection .WelcomeForm form a {
    width: 100% !important;
    margin-left: 5px !important;
  }
  .footer .footer-links ul {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    justify-content: start;
  }
  .footer .footer-links ul li {
    border-right: 0 !important;
    width: 50%;
    text-align: left;
  }
  .footer .footer-links ul li a {
    font-size: 12px !important;
    padding: 0 !important;
    white-space: nowrap;
  }
  .transfer_info_page .transfer_details h5 {
    font-size: 20px !important;
  }
  .OtherFooter .providers_sec {
    display: none;
  }
  *:not(:empty) {
    transform-style: inherit !important;
  }
  .WithdrawPageSection .WithDrawSection.tabs-area {
    width: 100% !important;
  }
  .WithdrawGif {
    display: none;
  }
  .WithDrawSection.tabs-area ul li button {
    font-size: 11px !important;
  }
  .WithDrawBankDetails form {
    padding: 30px 0 0 !important;
  }
  .main .mainBanner .slick-slide img {
    height: 200px !important;
    max-height: 200px !important;
  }
  .header.aftrlgn {
    width: 100% !important;
  }
  main .top_banner_sec {
    margin-top: 0 !important;
  }
  main.main .mainBanner.afterloginbanner img {
    height: 200px !important;
    max-height: 200px !important;
  }
  .header .headerRight .user-drop .user-button {
    width: 28px;
    height: 28px;
  }
  .header.aftrlgn .headerlogin {
    padding: 0 5px !important;
  }
  .header .headerRight .user-drop .user-button svg {
    width: 18px;
  }
  .header .headerRight .header-balance ul::after,
  .header .headerRight .funds ul::after {
    display: none;
  }
  .pagecontent .terms-content ul li {
    width: 30% !important;
    margin: 1% !important;
  }
  .main_progress ul {
    flex-wrap: wrap;
    padding: 0 !important;
    justify-content: center;
    margin-top: 0 !important;
  }
  .main_progress ul li img {
    height: 55px !important;
  }
  .main_progress ul li a p {
    white-space: inherit !important;
  }
  .main_progress ul li {
    width: 47% !important;
    margin: 1% !important;
  }
  .depositSection .heading_img h5 {
    font-size: 17px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 84%;
    height: 75px !important;
    display: block !important;
    top: 27px;
    letter-spacing: inherit !important;
  }
  .responsive_headings {
    font-size: 17px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 84%;
    height: 75px !important;
    display: block !important;
    top: 27px;
    letter-spacing: inherit !important;
  }
  .tabs_neww {
    margin-top: 0 !important;
  }
  .tabs_neww .nav-tabs {
    padding: 0 10px;
  }
  .appsection {
    flex-wrap: wrap;
  }
  .appsection .app-left-part,
  .appsection .app-right-part {
    width: 100% !important;
  }
  .tabs_neww .nav-tabs li button span img {
    width: 22px !important;
  }
  .main_progress {
    flex-wrap: wrap;
    padding: 0 10px !important;
    margin-top: -25px !important;
  }
  .depositSection {
    margin-top: 0 !important;
    padding: 0 !important;
  }
  .depositSection .depositBottmScetion {
    padding: 20px 0 !important;
  }
  .bottom_cont {
    padding: 0 15px !important;
  }
  .welcome-offer .WelcomeContentSection {
    padding: 22px 10px 10px !important;
  }
  .bottom_cont p {
    margin-bottom: -5px !important;
  }
  .welcome-offer .WelcomeContentSection .WelcomeForm {
    margin-top: 28px !important;
  }
  .progresss {
    width: 42% !important;
  }
  .promo-section ul li {
    width: 48.33% !important;
  }
  .CasinoSecton .tab-content .games-section ul li {
    width: 47.5% !important;
  }
  .logo {
    max-width: 100px;
  }
}
@media screen and (max-width: 376.98px) {
  .main .PageSection .PageRightBar .tabs-area ul li .nav-link {
    font-size: 9px !important;
  }
}
/*
}


.header .navbar .navbar-collapse {
  background: var(--secondaryClr);
  margin-left: 15px;
  padding: 5px;
  border-radius: 22px;
}
.header .navbar .navbar-collapse .navbar-nav a {
  color: var(--whiteClr);
  padding: 0 12px;
}
.header .navbar .navbar-collapse .navbar-nav a svg,
.header .navbar .navbar-collapse .navbar-nav a img {
  fill: var(--whiteClr);
  width: 20px;
  height: 20px;
}
.header .navbar .navbar-collapse .navbar-nav a:hover {
  opacity: 0.8;
}
.header .headerRight {
  display: flex;
  align-items: center;
  fill: var(--whiteClr);
}
.header .headerRight .path-stroke {
  stroke: var(--whiteClr);
}
.header .headerRight .funds ul {
  background: var(--secondaryClr);
  border-radius: 25px;
  padding: 4px 12px;
  margin-right: 12px;
}
.header .headerRight .funds ul li {
  position: relative;
  margin: 0 10px;
}
.header .headerRight .funds ul li:first-child:after {
  border-right: 1px solid var(--whiteClr);
  content: "";
  position: absolute;
  height: 16px;
  top: 3px;
  right: -11px;
}
.header .headerRight .funds ul li a {
  color: var(--whiteClr);
}
.header .headerRight .funds ul li a {
  padding-left: 8px;
}
.header .headerRight ul {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.header .headerRight ul li {
  margin: 0 5px;
  position: relative;
}
.header .headerRight ul li .notification-count {
  width: 12px;
  height: 12px;
  background: var(--siteRedClr);
  color: var(--whiteClr);
  border-radius: 50%;
  position: absolute;
  right: -2px;
  top: -2px;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .headerRight .menu-btn {
  padding: 0;
  background: none;
  border: none;
}
.header .headerRight .menu-btn:hover,
.header .headerRight .menu-btn:focus {
  background: none !important;
  border-color: transparent !important;
}
.header .headerRight .menu-btn svg {
  width: 20px;
  height: auto;
  margin-left: 5px;
  fill: var(--primaryClr);
} */

* {
  padding: 0;
  margin: 0;
}

:root {
  --primaryClr: #169a85;
  --secondaryClr: #1c1d21;
  --whiteClr: #fff;
  --darkClr: #000;
  --grayClr: #a4a3a8;
  --lightGrayClr: #e4e4e4;
  --siteGreenClr: #508334;
  --siteYellowClr: #edbf2e;
}

/*=============== Common CSS ===============*/
html,
body {
  background: var(--whiteClr);
  font-size: 14px;
}
body:not(.mobile)::-webkit-scrollbar,
.nice-scrollbar::-webkit-scrollbar {
  width: 5px;
}
body:not(.mobile)::-webkit-scrollbar-thumb,
.nice-scrollbar::-webkit-scrollbar-thumb {
  background: #919595;
  border-radius: 0;
  border: solid 1px #919595;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
body:not(.mobile)::-webkit-scrollbar-track,
.nice-scrollbar::-webkit-scrollbar-track {
  background: #e4e4e4;
  margin: 2px 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
button:focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.form-control:focus,
.custom-select:focus {
  box-shadow: none;
}
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  transition: all 0.5s ease-in-out;
}
a:hover {
  text-decoration: none;
}
hr {
  border-color: var(--siteGreenClr);
}

/* betslip */
.betslip-wrapper {
  background: var(--headClr);
  display: flex;
  max-width: 350px;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  transition: 0.5s;
  right: 0;
  z-index: 999;
  box-shadow: 0 0px 8px rgba(255, 189, 0, 0.12);
}
.betslip-wrapper .betslip-head {
  border-top-left-radius: 0.5rem;
  box-shadow: 0 2px 18px rgb(0 0 0 / 25%);
  min-width: 100%;
  width: 100%;
  margin-left: 0 !important;
  right: 0;
  top: 0;
  z-index: 98;
}
.betslip-wrapper .betslip .betslip_close_btn {
  border-bottom: 1px solid var(--secondaryClr) !important;
  text-align: right !important;
}
.betslip-wrapper .closebtn {
  font-size: 18px;
  color: var(--whiteClr);
  margin-left: 0;
}
.betslipStart ul {
  border-bottom: 1px solid var(--secondaryClr);
  justify-content: center;
}
.betslipStart ul li a {
  background: transparent !important;
  color: var(--whiteClr) !important;
  font-weight: 600;
  text-align: center;
  border: 0 !important;
  padding: 0.5rem 1rem;
  font-size: 14px;
}
.betslipStart ul li a.active {
  color: var(--primaryClr) !important;
}
.betslipStart .betsTsbs ul.nav-tabs li {
  flex: 1;
}
.betslipStart .betslip-content {
  border-bottom: 0;
  padding: 1rem;
}
.betslipStart .betslip-content .match-name {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.betslipStart .betslip-content .match-name p {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0;
}
.betslipStart .betslip-content .match-name p .info {
  display: inline-block;
  margin-right: 0.75rem;
}
.betslipStart .betslip-content .match-name .betslip-close {
  background: #fff;
  border-radius: 50%;
  color: #080325;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  height: 16px;
  line-height: 13px;
  text-align: center;
  width: 16px;
}
.betslipStart .betslip-content .match-result {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}
.betslipStart .betslip-content .match-result .team-name {
  color: var(--primaryClr);
  font-size: 0.9rem;
  font-weight: 600;
}
.betslipStart .betslip-content .match-result .team-name span {
  color: #fff;
}
.betslipStart .betslip-content .match-result .odds {
  background: var(--bodyClr);
  border: 1px solid var(--secondaryClr);
  border-radius: 25px;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  height: 100%;
  min-width: 60px;
  padding: 0.35rem 0.5rem;
  text-align: center;
}
.betslipStart .betslip-content .stack-win-value {
  align-items: center;
  display: flex;
  padding-top: 1rem;
}
.betslipStart .betslip-content .stack-win-value .stack,
.betslipStart .betslip-content .stack-win-value .winning {
  flex: 1 1;
}
.betslipStart .betslip-content .stack-win-value .stack .form-control {
  -webkit-animation: glowingGreen 1s infinite;
  animation: glowingGreen 1s infinite;
  background: #220a49;
  border: 1px solid var(--secondaryClr);
  color: var(--whiteClr);
  max-width: 120px;
}
.betslipStart .betslip-content .stack-win-value .winning p {
  color: #999;
  margin-bottom: 0;
  margin-left: 1rem;
}
.betslipStart .betslip-content .stack-win-value .winning p span {
  color: var(--whiteClr);
}
.betslipStart .betslip-content .auto-stack {
  padding: 1rem 0;
  border-bottom: 1px solid var(--secondaryClr);
}
.betslipStart .betslip-content .auto-stack ul {
  align-items: center;
  display: flex;
  border-bottom: 0;
}
.betslipStart .betslip-content .auto-stack ul li {
  flex: 1 1;
  margin-right: 0.5rem;
}
.betslipStart .betslip-content .auto-stack ul li .stack-btn {
  background: var(--secondaryClr);
  color: #fff;
  padding: 0.25rem 0.5rem;
  width: 100%;
}
.betslipStart .betslip-content .bet-now {
  margin-top: 15px;
}
.betslipStart .betslip-content .bet-now .place-btn {
  background: var(--primaryClr);
  color: var(--blackClr);
  font-size: 1.15rem;
  font-weight: 600;
  width: 100%;
}
.betslipStart .open-bets {
  padding-top: 1rem;
}
.betslipStart .open-bets form {
  padding-left: 1rem;
}
.betslipStart .open-bets form .form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.betslipStart .open-bets form .form-check .form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.betslipStart .open-bets form label {
  color: #fff;
  cursor: pointer;
}
.betslipStart .open-bets .my-bets .bet-heading {
  background: #1d1a3e;
  padding: 0.5rem;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.betslipStart .open-bets .my-bets .bet-heading li:first-child {
  font-size: 0.9rem;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.betslipStart .open-bets .my-bets .bet-heading li {
  color: #ddd;
  font-size: 0.85rem;
  text-align: right;
  width: 25%;
}
.betslipStart .open-bets .my-bets body {
  height: auto !important;
  max-height: calc(100vh - 118px);
  overflow-y: auto;
}
.betslipStart .open_bets {
  padding-top: 1rem;
}
.react-tel-input .country-list .country {
  text-align: left;
}
.react-tel-input .country-list .country span {
  color: #000;
}
@media only screen and (max-width: 991px) {
  .BlogSection ul li a .BlogListing .BlogListImg {
    height: auto !important;
  }
  .main .PageSection,
  .main .PageSection .PageRightBar .ProfileMainSec {
    flex-direction: column;
  }
  main.main .PageSection .PageLeftBar,
  main.main .PageSection .PageRightBar,
  main.main .PageSection .PageRightBar .ProfileMainSec .ProfileImgSec,
  main.main .PageSection .PageRightBar .ProfileMainSec .ProfileForm {
    width: 100% !important;
  }
  .main .PageSection .PageRightBar .ProfileMainSec .edit-btn {
    right: 0 !important;
  }
  .main .PageSection .PageLeftBar .LeftBarSide {
    padding: 10px !important;
  }
}
@media only screen and (max-width: 767px) {
  .footer .footer-links {
    padding: 0 10px;
    flex-wrap: wrap;
  }
  .footer .footer-links .FooterLinksSection h3 {
    white-space: nowrap;
  }
  .footer {
    margin-top: 0;
  }
  .FooterPart {
    margin-bottom: 15px;
  }
  .FooterPart:nth-child(1),
  .FooterPart:nth-child(3) {
    width: 60% !important;
  }
  .FooterPart:nth-child(2),
  .FooterPart:nth-child(4) {
    width: 40% !important;
  }
  body select.language-select {
    margin-right: 3px !important;
    margin-left: 3px;
    padding: 5px 4px !important;
  }
  .main .PageSection .PageRightBar .tabs-area .tab-content form .btn {
    width: 100% !important;
  }
  .footer p {
    font-size: 9px !important;
  }
  .main .match_slider_sec .match-icon div a img {
    margin-right: 0 !important;
    height: 22px !important;
  }
  .mobilepromotion a {
    color: var(--whiteClr);
    font-size: 14px;
    padding: 8px 6px;
    border-radius: 5px;
    position: relative;
    display: none;
    text-transform: capitalize;
    margin: 0 0px 0;
    border: 1px solid #fff;
  }
  .slots-section ul li {
    width: 47.5% !important;
  }
  .main .match_slider_sec .match-icon .slick-arrow {
    width: 25px !important;
  }
  .match_slider_sec .match-icon .slick-list {
    padding: 0 28px !important;
  }
  .main .match_slider_sec .match-icon div a p {
    width: 100%;
    text-align: center;
    font-size: 12px !important;
  }
  .main .match_slider_sec .match-icon .slick-arrow.slick-next::before,
  .main .match_slider_sec .match-icon .slick-arrow.slick-prev::before {
    width: 12px !important;
    height: 12px !important;
  }
  .live_dealer_casino .gamesSection {
    width: 100% !important;
  }
  .sports-section ul li {
    padding: 5px !important;
    width: 100px !important;
    margin-left: 8px !important;
    border-radius: 3px !important;
    overflow: visible !important;
  }
  .sports-section ul li:first-child {
    margin-left: 0 !important;
  }
  .sports-section ul li a .right-content {
    padding: 0 !important;
  }
  .sports-section ul li a .img-head {
    width: 100% !important;
    height: 60px !important;
    border-radius: 3px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sports-section ul li a {
    flex-direction: column !important;
  }
  .sports-section ul li a .right-content p {
    display: none !important;
  }
  .sports-section ul li a .img-head img {
    width: 40px !important;
  }
  .sports-section {
    padding: 0 0 0 8px !important;
    margin-top: -18px !important;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .live_dealer_casino {
    flex-wrap: wrap;
    padding: 8px !important;
    padding: 18px !important;
  }
  .right-sportsbanner {
    display: none !important;
  }
  p.spribe,
  .live_dealer_casino h4 a {
    padding: 0 !important;
  }
  .live_dealer_casino .gamesSection .casino_games ul li a {
    width: 100% !important;
    padding: 0 !important;
  }
  HEAD
    body
    .live_dealer_casino
    .casino_games
    .allGames
    .spribegamesection
    ul
    > div {
    width: 49% !important;
  }
  .live_dealer_casino
    .gamesSection
    .allGames
    .spribegamesection
    ul
    > div
    .game_box {
    width: 100% !important;
  }
  .sports-section ul li a .right-content h2 {
    white-space: nowrap !important;
    font-size: 13px !important;
    margin-top: 10px;
  }
  .header .headerRight .user-drop {
    margin-left: 5px;
  }
  .header .headerRight .user-drop .user-content-section .user-content ul li {
    width: 48% !important;
  }
  .header
    .headerRight
    .user-drop
    .user-content-section
    .user-content
    .user-other-option
    ul
    li {
    width: calc(33.33% - 20px) !important;
  }
  .main .PageSection .PageRightBar .promosection {
    width: 100%;
    flex-wrap: wrap;
  }
  .main .PageSection .PageRightBar .promosection .left-promotion,
  .main .PageSection .PageRightBar .promosection .right-promo {
    width: 100% !important;
  }
  body .header.aftrlgn ~ main .top_banner_sec {
    margin-top: 0 !important;
  }
  .steps-canvas.LoginImg::after {
    display: none !important;
  }
  .steps-canvas .offcanvas-body,
  .steps-canvas .offcanvas-header {
    width: 100% !important;
  }
  .promo-section ul li .promo-content {
    flex-wrap: wrap;
  }
  .promo-section ul li .promo-content .content {
    padding: 20px 10px !important;
  }
  .promo-section ul li .promo-content .content p {
    min-height: 150px !important;
    padding-top: 13px !important;
    margin: 0 !important;
  }
  .promo-section ul li .promo-content .content h4 {
    min-height: 40px !important;
    margin: 0 !important;
    font-size: 13px !important;
  }
  .promo-section ul li .promo-content img {
    border-radius: 10px !important;
  }
  .faq-section .faq-image img {
    width: 320px !important;
  }
  .faq-section .faq-image .FaqAnimation img {
    width: 170px !important;
  }
  .faq-section {
    flex-wrap: wrap;
  }
  .faq-section .accordion {
    margin-top: 12px;
  }
  .main
    .account-statement
    ul
    li.casino-bets-sectiom
    .casinoBetsDetails
    .details,
  .main .account-statement ul li.casino-bets-sectiom .casinoBetsDetails .h4,
  .main .account-statement ul li.casino-bets-sectiom .casinoBetsDetails small,
  .main .account-statement ul li.casino-bets-sectiom .casinoBetsDetails .h5,
  .main .account-statement ul li.casino-bets-sectiom .casinoBetsDetails span {
    padding: 0 12px 0 0 !important;
    white-space: nowrap;
    width: auto !important;
    display: block !important;
    border-bottom: 1px solid rgba(138, 146, 171, 0.3) !important;
    padding-bottom: 18px !important;
  }
  .main .account-statement ul {
    overflow-x: scroll !important;
    overflow-y: hidden !important;
  }
  .main .account-statement ul li {
    border: 0 !important;
    padding: 12px 20px 12px 20px !important;
  }
  .main .account-statement ul span {
    display: none !important;
  }
  .modal.login-modal .modal-content .modal-body .login-section {
    flex-wrap: wrap;
  }
  .modal.login-modal .modal-content .modal-body .login-section .login-leftPart {
    width: 100% !important;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 25px;
    display: none;
  }
  .modal.login-modal
    .modal-content
    .modal-body
    .login-section
    .Login-rightPart {
    width: 100% !important;
  }
  .modal.login-modal
    .modal-content
    .modal-body
    .login-section
    .Login-rightPart
    .form-steps
    button {
    width: 100% !important;
  }
  .steps-canvas .form-steps {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .main .PageSection .PageRightBar .RightBarHead {
    padding: 15px !important;
    margin-bottom: 0 !important;
  }
  .AccountSection {
    padding: 22px 1rem !important;
  }
  .referral_card_main {
    margin-top: 0 !important;
  }
  .main .PageSection .PageRightBar .tabs-area {
    margin-top: 0 !important;
    border-radius: 0 !important;
  }
  .main .PageSection .PageRightBar .tabs-area .upi-list li {
    width: 15% !important;
  }
  .transfer_info_page .PageRightBar .transfer_details {
    padding: 20px !important;
  }
  .header .headerRight .header-balance,
  .header .headerRight .funds,
  .language-option {
    margin: 0 5px !important;
  }
  .main .payment-info .btn {
    font-size: 12px;
  }
  .main .payment-info .btn .exch-funds-form {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 10px;
    font-weight: bold;
  }
}
.Toastify {
  position: absolute;
  z-index: 999999999999;
}
.iframe-div {
  height: 100vh;
  max-height: -webkit-fill-available;
}
.oddsChanged {
  background-color: rgb(252, 252, 78) !important;
}

@media only screen and (max-width: 575px) {
  .header .headerRight .social {
    display: none !important;
  }
  .header.beforeheader .headerRight .btn_signup,
  .header.beforeheader .headerRight .btn_signin {
    padding: 8px 8px 8px !important;
    margin-left: 5px !important;
    font-size: 13px;
    white-space: nowrap;
  }
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .ezugi_iframe iframe {
    max-height: calc(100vh - 280px) !important;
    height: calc(100vh - 280px) !important;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .ezugi_iframe iframe {
    height: calc(100vh - 280px) !important;
    max-height: calc(100vh - 280px) !important;
  }
}
