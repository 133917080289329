@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css2?family=Michroma&family=Montserrat:wght@900&family=Proza+Libre:wght@700&display=swap");
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Chela+One&display=swap);

* {
  margin: 0;
  padding: 0;
}

:root {
  --primaryClr: #e20001;
  --secondaryClr: #49af28;
  --greenClr: #0d8d00;
  --headClr: #191919;
  --bodyClr: #201e1e;
  --whiteClr: #ffffff;
  --blackClr: #1e1e1e;
  --lightClr: rgba(255, 255, 255, 0.6);
  --oddsBack: #72bbef;
  --oddsLay: #faa8ba;
  --siteRedClr: #d9534f;
  --siteGreenClr: #27ae60;
  --color: #f3738a;
  --border: 2px;
  --slant: 0.5em;
}
.light-mode:root {
  --primaryClr: #ffb80c;
  --secondaryClr: #999;
  --headClr: rgba(0, 0, 0, 0.175);
  --bodyClr: #ddd;
  --whiteClr: #000000;
  --blackClr: #ffffff;
}
.loader-img {
  background: var(--blackClr);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 150px;
  }
}

body {
  background: var(--bodyClr);
  color: var(--whiteClr);
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  top: 0 !important;
}
.container-fluid {
  @media screen and (min-width: 1320px) {
    width: 90%;
    max-width: 100%;
  }
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  transition: all 0.3s;
  &:focus,
  &:focus-visible {
    outline: none;
  }
}
.btn-primary {
  background: var(--primaryClr);
  color: var(--whiteClr);
  border-color: var(--primaryClr);
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  padding: 6px 8px;
  &:hover,
  &:focus {
    background: var(--primaryClr) !important;
    color: var(--whiteClr) !important;
    opacity: 0.9;
    border-color: var(--primaryClr) !important;
  }
}
.btn-outline-primary {
  border-color: var(--primaryClr);
  &:hover,
  &:focus {
    background: transparent !important;
    color: #000000 !important;
    opacity: 0.9;
    border-color: var(--primaryClr) !important;
  }
}
.btn-outline-secondary {
  font-size: 11px;
  border-radius: 50px;
  padding: 6px 8px;
}
.back-link,
.back_btn {
  color: var(--whiteClr);
  font-size: 12px !important;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.65rem;
  border: 1px solid var(--whiteClr);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s;
  &:hover,
  &:focus {
    background: var(--blackClr);
    color: var(--whiteClr);
    border-color: var(--blackClr);
  }
}
img {
  width: 100%;
  height: auto;
}
input,
input.form-control {
  border-radius: 0;
  border-color: var(--primaryClr);
  font-size: 14px;
  font-weight: 300;
  &:focus {
    box-shadow: none !important;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--bodyClr) inset !important;
  -webkit-text-fill-color: var(--whiteClr) !important;
}

body {
  .theme-btn {
    display: none;
    .theme-icon {
      width: 25px;
      height: 25px;
      border: 1px solid var(--whiteClr);
      border-radius: 50%;
      text-align: center;
      line-height: 13px;
      padding: 4px;
      .dark_theme {
        display: none;
      }
    }
  }
  &.light-mode {
    .theme-btn {
      .theme-icon {
        border-color: var(--whiteClr);
        .dark_theme {
          display: block;
        }
        .light_theme {
          display: none;
        }
      }
    }
  }
}

.header {
  position: relative;
  top: 0;
  left: 0;
  // background: var(--headClr);
  width: 100%;
  z-index: 999;
  &.beforeheader {
    .top_head {
      padding: 5px 0 5px;
      // border-bottom: 1px solid #9d6600;
    }

    .btn_signin {
      background: transparent !important;
      color: var(--whiteClr);
      padding: 8px 32px;
      border-radius: 3px;
      font-size: 16px;
      font-weight: normal;
      text-transform: capitalize;
      position: relative;
      border: 1px solid var(--whiteClr);
      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        background: rgb(2, 184, 116);
        background: linear-gradient(
          51deg,
          rgb(167 12 12) 0%,
          rgb(252 0 0) 100%
        );
        height: 100%;
        width: 0px;
        transition: all 0.4s;
        border-radius: 5px;
        z-index: -1;
      }
      &:hover::before {
        width: 100%;
      }
      &:hover span {
        color: var(--whiteClr);
        z-index: 99 !important;
        position: relative;
      }
    }
    .btn_signup {
      margin-left: 25px;
      background: var(--primaryClr);
      border-color: var(--primaryClr);
      color: var(--whiteClr);
      padding: 8px 32px !important;
      border-radius: 3px;
      font-size: 16px;
      font-weight: normal;
      text-transform: capitalize;
      position: relative;
      &:hover {
        border-color: var(--secondaryClr) !important;
        background: rgb(195, 54, 19) !important;
        background: linear-gradient(
          51deg,
          rgba(195, 54, 19, 1) 0%,
          rgba(238, 139, 114, 1) 100%
        ) !important;
      }
      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        border-color: var(--secondaryClr);
        background: rgb(195, 54, 19);
        background: linear-gradient(51deg, rgb(0 115 46) 0%, rgb(2 75 33) 100%);
        height: 100%;
        width: 0px;
        transition: all 0.4s;
        border-radius: 5px;
        z-index: -1;
      }
      &:hover::before {
        width: 100%;
      }
      &:hover span {
        color: var(--whiteClr);
        z-index: 99 !important;
        position: relative;
      }
    }
  }
  &.beforeheader {
    .navbar a {
      color: var(--whiteClr);
      font-size: 14px;
      padding: 0;
      position: relative;
      display: flex;
      text-transform: uppercase;
      padding: 8px 12px !important;
      &::after {
        display: none;
      }
    }
  }
  &.aftrlgn {
    padding: 0px;
    background-color: var(--headClr);
    width: 100%;
    margin: 0 auto;
    border-radius: 0;
    .headerlogin {
      height: 72px;
      display: flex;
      align-items: center;
      .headerLoginSection {
        width: 100% !important;
        height: 100%;
      }
    }

    .top-head {
      display: flex;
      justify-content: space-between;
      height: 40px;
      padding: 0 40px;
      margin-top: 0;
      background-color: var(--secondaryClr1);
      transition: margin-top 0.2s;
      .tophead-left ul {
        display: flex;
        align-items: center;
        height: 40px;
        li a {
          display: flex;
          margin-right: 30px;
          align-items: center;
          img {
            width: 14px;
            height: 14px;
          }
          p {
            margin: 0;
            font-size: 12px;
            margin-left: 8px;
            color: #fff;
            font-weight: 600;
          }
        }
      }
      .tophead-right {
        display: flex;
        align-items: center;
        .time-date span {
          color: #fff;
          margin-right: 30px;
          font-size: 12px;
          font-weight: 700;
        }
        .language select {
          background: transparent;
          border: 0;
          color: #fff;
          padding: 0;
          font-size: 14px;
          &:focus-visible {
            outline: 0;
          }
          option:checked,
          option:hover {
            border-bottom: 1px solid var(--secondaryClr);
            background: #172c3f;
            border: 1px solid var(--secondaryClr);
          }
          option {
            border-bottom: 1px solid var(--secondaryClr);
            background: #172c3f;
            border: 1px solid var(--secondaryClr);
          }
        }
      }
    }
  }
  .middle-part {
    .navbar {
      .navbar-collapse {
        background-color: transparent !important;
      }
    }
  }
  .leftbar_toggle {
    .btn {
      background: transparent;
      border: 0;
      padding: 0;
      svg {
        width: 18px;
        height: 18px;
        display: block;
        fill: var(--primaryClr);
      }
      &:hover,
      &:focus {
        background: transparent !important;
      }
    }
  }
  .logo {
    max-width: 125px;
    margin-right: 40px;
    @media screen and (max-width: 767.98px) {
      max-width: 125px;
      margin-right: 0 !important;
    }
  }
  .navbar {
    padding: 0;
    .navbar-collapse {
      border-radius: 22px;
      .navbar-nav {
        gap: 15px;
        a {
          color: var(--whiteClr);
          font-size: 15px;
          position: relative;
          display: flex;
          text-transform: capitalize;
          &::after {
            content: "";
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            width: 50%;
            height: 5px;
            border-radius: 5px;
            background: var(--primaryClr);
            opacity: 0;
            transition: all 0.3s;
          }
          img.on-non-hover {
            display: block;
          }
          img.on-hover {
            display: none;
          }
          &:hover img.on-non-hover {
            display: none;
          }
          &:hover img.on-hover {
            display: block;
          }
          .exchangeflex {
            display: flex;
            margin-right: 5px;
            -webkit-animation: sports-book-bouncing 3s linear infinite alternate;
            animation: sports-book-bouncing 3s linear infinite alternate;
            svg,
            img {
              -webkit-animation: sports-book-rotate 3s linear infinite alternate;
              animation: sports-book-rotate 3s linear infinite alternate;
              margin-right: 0px !important;
            }
          }
          svg,
          img {
            fill: var(--whiteClr);
            height: 22px;
            filter: brightness(0) invert(1);
            margin-right: 8px !important;
          }
          &:hover,
          &.active {
            opacity: 1;
            color: var(--primaryClr);
            img {
              filter: inherit !important;
            }
          }
          &:hover::after,
          &.active::after {
            width: calc(100% + 20px);
            opacity: 1;
          }
        }
      }
    }
  }
  .headerRight {
    display: flex;
    align-items: center;
    fill: var(--whiteClr);
    .header-balance,
    .funds {
      margin: 0 15px;
      display: flex;
      background: var(--primaryClr);
      font-size: 14px;
      height: 36px;
      font-weight: 600;
      border-radius: 6px;
      ul {
        margin: 0 15px 0 0;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        padding-left: 15px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0px;
          width: 1px;
          height: 30px;
          background-color: #d88282;
        }
      }
    }
    .user-drop {
      .user-button {
        cursor: pointer;
        width: 28px !important;
        height: 28px !important;
      }
      .user-content-section {
        position: absolute;
        top: 80px;
        right: 0;
        width: 285px;
        transition: all 0.3s;
        .user-content {
          background-color: var(--whiteClr);
          width: 100%;
          padding: 10px;
          border: 1px solid #f2f2f2;
          border-radius: 10px;
          box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
          ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 15px 10px;
            li {
              margin-bottom: 8px;
              font-size: 15px;
              width: 50%;
              span {
                color: var(--blackClr);
                font-size: 14px;
                font-weight: 700;
              }
              p {
                display: flex;
                flex-direction: column;
                margin: 0;
                color: #777;
                font-size: 14px;
                span {
                  color: var(--blackClr);
                }
              }
              a {
                color: #777;
                width: 100%;
                font-size: 12px;
                white-space: nowrap;
                display: flex;
                flex-direction: column;
                align-items: start;
                cursor: text;
                // &.signout {
                //   padding: 9px 30px;
                //   border: 1px solid #f2f2f2 !important;
                //   border-radius: 5px;
                //   background: var(--whiteClr);
                //   font-weight: 700;
                //   font-size: 14px;
                //   color: #1f1f1f;
                //   transition: all 0.3s;
                //   &:hover {
                //     color: var(--whiteClr);
                //     background-color: var(--primaryClr);
                //   }
                // }
              }
            }
          }
          .user-other-option {
            background: var(--whiteClr);
            padding: 0;
            border-radius: 0.75rem;
            ul {
              display: flex;
              align-items: flex-start;
              justify-content: center;
              flex-wrap: wrap;
              background-color: #0b151e;
              border-radius: 8px;
              margin-top: 8px;
              padding: 13px 0;
              li {
                width: calc(33.33% - 20px);
                margin: 7px 10px;
                text-align: center;
                color: var(--bodyClr);
                a {
                  color: var(--whiteClr);
                  width: 100%;
                  font-size: 12px;
                  white-space: nowrap;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  margin: 0 auto;
                }
                img {
                  width: 24px;
                  padding-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
    .headerMainDropdown {
      position: relative;
      button.dropdown-toggle {
        width: 35px !important;
        height: 35px !important;
        border-radius: 50% !important;
        background-color: #283968 !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        &:hover ~ .dropdown-menu.show {
          transform: translateY(0);
          opacity: 1;
          pointer-events: auto;
        }

        &:after {
          display: none !important;
        }
        svg {
          width: 20px !important;
        }
      }
      .dropdown-menu {
        background-color: #283352 !important;
        min-width: 230px !important;
        top: 15px !important;
        border-radius: 5px;
        padding: 10px 12px 12px !important;
        opacity: 0;
        transform: translateY(15px);
        transition: all 0.3s;
        &::before {
          position: absolute;
          top: -20px;
          right: -5px;
          content: "";
          background: #283352;
          clip-path: polygon(47% 3%, 0% 100%, 82% 100%);
          width: 30px;
          height: 30px;
        }
        a {
          color: #fff;
          margin-bottom: 15px !important;
          padding: 0 !important;
          font-weight: 200 !important;
          display: flex;
          align-items: center;
          &:last-child {
            margin-bottom: 0 !important;
          }
          img {
            width: 20px;
            margin-right: 8px;
          }
          button {
            width: 100% !important;
            height: auto !important;
            color: #fff;
            font-size: 14px;
            font-weight: 200 !important;
            display: flex !important;
            align-items: center !important;
            flex-direction: row !important;
            &:hover,
            &:focus {
              background-color: transparent !important;
              border-radius: 0 !important;
            }
          }
          span {
            color: #fff !important;
            font-size: 14px !important;
            font-weight: 200 !important;
          }
        }
      }
    }
    .path-stroke {
      stroke: var(--whiteClr);
    }
    .dropdown {
      .btn {
        background: transparent;
        padding: 0;
        border: 0;
        display: flex;
        align-items: center;
        position: relative;
        font-size: 14px;
        img {
          width: 22px;
          margin-right: 8px;
        }
        span {
          font-size: 14px !important;
          font-weight: bold !important;
          padding: 0 3px;
        }
        &:after {
          position: absolute;
          top: 28px !important;
          left: 45px;
          display: none;
        }
      }
      .dropdown-menu {
        min-width: 250px;
        background: rgb(32 42 57 / 81%);
        padding: 0;
        border: 0;
        .dropdown-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 10px;
          color: var(--whiteClr);
          h6 {
            margin-bottom: 0;
          }
          span {
            color: #ff5513;
            font-size: 12px;
            font-weight: 600;
          }
          &.deposit {
            background: var(--siteGreenClr);
            color: var(--whiteClr);
            font-weight: 600;
            width: calc(100% - 20px);
            text-align: center;
            display: block;
            margin: 10px;
            border-radius: 5px;
            &:hover {
              background: var(--siteGreenClr);
            }
          }
          &:hover {
            background: transparent;
          }
        }
      }
    }
    .funds {
      ul {
        li {
          a {
            color: var(--whiteClr);
            span {
              padding-left: 5px;
            }
            &.deposit {
              background: var(--primaryClr);
              font-size: 14px;
              font-weight: 600;
              border-radius: 10px;
              display: block;
              height: 100%;
              text-transform: capitalize;
            }
          }
        }
      }
    }
    .social {
      display: flex;
      align-items: center;
      margin-right: 5px;
      li {
        margin: 0 5px;
        position: relative;
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          width: 30px;
        }
      }
    }
    .menu-btn {
      background: #283968;
      width: 40px;
      height: 40px;
      border: 0 !important;
      display: flex;
      padding: 0;
      justify-content: center;
      align-items: center;
      &:hover,
      &:focus {
        background: none !important;
        border-color: transparent !important;
      }
      svg {
        width: 20px;
        height: auto;
        // margin-left: 5px;
        fill: #1877f2;
      }
    }
  }
  .bottom_head {
    background: var(--headClr);
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-x: auto;
      text-align: center;
      @media screen and (max-width: 991.98px) {
        justify-content: flex-start;
      }
      li {
        display: inline-block;
        margin: 0 5px;
        a {
          color: var(--whiteClr);
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          display: block;
          padding: 6px 10px;
          @media screen and (max-width: 575.98px) {
            padding: 2px 5px 2px;
          }
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}

.logo,
.footer-logo {
  img {
    &.light-theme {
      display: none;
    }
  }
}
.light-mode {
  .logo,
  .footer-logo {
    img {
      &.light-theme {
        display: block;
      }
      &.dark-theme {
        display: none;
      }
    }
  }
}
.light-mode {
  .offcanvas {
    .offcanvas-body {
      .social_links {
        li {
          svg {
            filter: brightness(0);
          }
        }
      }
    }
  }
}

.back-link {
  font-size: 10px;
  color: var(--primaryClr);
  text-transform: uppercase;
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
  }
}
.offcanvas {
  border: none !important;
  z-index: 999999999;
}
.offcanvas-header {
  background: var(--headClr);
  color: var(--whiteClr);
  .profile-img {
    width: 48px;
    height: 48px;
    background: var(--bodyClr);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    fill: var(--whiteClr);
  }
  .user-info {
    color: var(--whiteClr);
    font-size: 14px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      font-size: 16px;
      font-weight: bold;
    }
    a {
      color: var(--whiteClr);
    }
  }
  .btn-close {
    opacity: 1;
  }
}
.offcanvas-body {
  background: var(--bodyClr);
  .balance {
    font-size: 16px;
    color: var(--whiteClr);
    display: flex;
    align-items: center;
    svg {
      margin-right: 14px;
      fill: var(--primaryClr);
      stroke: var(--primaryClr);
    }
  }
  .balance-amount {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    color: var(--whiteClr);
  }
  .btn {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
  }
  .menu-items {
    border-top: 1px solid var(--whiteClr);
    margin: 30px -1rem 0 -1rem;
    li {
      border-bottom: 1px solid var(--whiteClr);
      a {
        display: flex;
        align-items: center;
        padding: 17px 1rem;
        font-size: 16px;
        color: var(--whiteClr);
        svg {
          margin-right: 15px;
          width: 22px;
          height: auto;
        }
      }
    }
  }
  ul.social_links {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    li {
      margin: 0 10px;
      position: relative;
      svg {
        fill: var(--whiteClr);
        stroke: var(--whiteClr);
        cursor: pointer;
      }
      .notification-count {
        width: 12px;
        height: 12px;
        background: var(--siteRedClr);
        color: var(--whiteClr);
        border-radius: 50%;
        position: absolute;
        right: -2px;
        top: -2px;
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.form-steps {
  text-align: center;
}
body.leftbat-opened {
  .leftbarSec {
    transform: translateX(0);
  }
}
.mt--14 {
  .leftbarSec {
    margin-top: -14px;
  }
}
.main {
  &.LandingPage {
    margin-top: -50px;
  }
  .leftbarSec {
    background: var(--secondaryClr);
    width: 200px;
    height: calc(100vh - 52px);
    overflow-y: auto;
    padding: 10px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media only screen and (max-width: 1199.98px) {
      position: fixed;
      transform: translateX(-100%);
      transition: all 0.3s;
      z-index: 98;
    }
    @media only screen and (max-width: 767.98px) {
      width: 70%;
    }
    .games_link {
      h6 {
        color: var(--primaryClr);
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px 10px;
        border-bottom: 1px solid var(--primaryClr);
      }
      ul {
        padding: 10px 0;
        li {
          cursor: pointer;
          border-bottom: 1px solid #333;
          > div {
            line-height: 35px;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            position: relative;
            &:before {
              position: absolute;
              content: "";
              top: 0;
              left: -10px;
              width: 2px;
              height: 100%;
              background: var(--primaryClr);
              opacity: 0;
              transition: all 0.3s;
            }
            &:hover {
              &:before {
                opacity: 1;
                left: 0;
              }
            }
            img,
            svg {
              width: 20px;
              height: auto;
              fill: var(--whiteClr);
            }
            span {
              color: var(--whiteClr);
              font-size: 12px;
              font-weight: 600;
              text-transform: uppercase;
              margin-left: 10px;
              opacity: 0.8;
            }
            .sport-event-count {
              background: var(--primaryClr);
              color: var(--blackClr);
              font-size: 12px;
              margin-left: auto;
              padding: 0.25em 0.4em;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .middleContentSec {
    width: 100%;
    @media only screen and (min-width: 1200px) {
      width: calc(100% - 600px);
      max-height: calc(100vh - 63px);
      overflow-y: auto;
      padding: 0 5px;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
        width: calc(100% - 500px);
      }
    }
  }
  .rightbarSec {
    background: var(--secondaryClr);
    width: 400px;
    height: calc(100vh - 52px);
    overflow-y: auto;
    padding: 10px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
      width: 300px;
    }
    @media only screen and (max-width: 1199.98px) {
      display: none;
    }
    h6 {
      color: var(--primaryClr);
      font-size: 12px;
      font-weight: 600;
      line-height: 28px;
      padding: 5px 10px;
      border-bottom: 1px solid var(--primaryClr);
    }
    .VSlider {
      background-color: var(--headClr);
      border-radius: 10px;
      padding: 20px 10px;
      margin: 14px 0;
      .slick-slider {
        position: relative;
        .slide_box {
          width: 70%;
          margin: 0 auto;
          position: relative;
          img {
            border-radius: 5px;
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.45);
            border-radius: 5px;
          }
        }
        .slick-dots {
          top: 40%;
          bottom: auto;
          right: -40px;
          left: auto;
          transform: rotate(90deg);
          width: auto;
          li {
            width: 32px;
            height: 32px;
            transform: rotate(-90deg);
            button {
              border-radius: 50%;
              text-align: center;
              width: 32px;
              height: 32px;
              line-height: 32px;
              padding: 0;
              &::before {
                color: #ccc;
                font-size: 15px;
                // font-family: Arial, Helvetica, sans-serif;
                text-shadow: 1px 1px 3px #555;
                width: 32px;
                height: 32px;
                line-height: 30px;
                opacity: 1;
                transition: all 0.4s;
              }
            }
            &:first-child {
              button {
                &::before {
                  content: "01";
                }
              }
            }
            &:nth-child(2) {
              button {
                &::before {
                  content: "02";
                }
              }
            }
            &:nth-child(3) {
              button {
                &::before {
                  content: "03";
                }
              }
            }
            &.slick-active {
              button {
                background: var(--primaryClr);
                &::before {
                  color: var(--whiteClr);
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
    .casino_games {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);
      a {
        position: relative;
        img {
          border-radius: 5px;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(#3f3f4f 10%, #000 100%);
          color: var(--primaryClr);
          font-weight: 700;
          line-height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          opacity: 0;
          border-radius: 5px;
          .game_type {
            font-size: 10px;
            text-transform: uppercase;
            max-width: 90px;
            transition: all 0.4s;
          }
        }
        &:hover {
          .overlay {
            opacity: 0.8;
            .game_type {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    &.matched_bets {
      @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        display: block;
      }
      @media only screen and (max-width: 991.98px) {
        display: none;
      }
      .accordion {
        .accordion-item {
          background-color: #333;
          padding-bottom: 5px;
          margin-bottom: 10px;
          border: 0;
          border-radius: 5px;
          box-shadow: 0 0 10px rgb(0 0 0 / 60%);
          .accordion-header {
            .accordion-button {
              background: transparent;
              width: 100%;
              text-align: left;
              padding: 5px 10px;
              border: 0;
              border-bottom: 1px solid var(--primaryClr);
              border-radius: 0;
              box-shadow: none;
              img {
                width: 20px;
                margin-right: 10px;
              }
              span {
                color: var(--primaryClr);
                font-size: 13px;
                font-weight: 600;
                line-height: 28px;
              }
              &:hover,
              &:focus {
                background: transparent !important;
              }
              &:after {
                content: none;
              }
            }
          }
          .accordion-body {
            color: var(--whiteClr);
            padding: 0;
            border: 0;
            .bets_box {
              .bet_info {
                .table {
                  background: var(--headClr);
                  margin-bottom: 0;
                  border-collapse: collapse;
                  overflow-x: auto;
                  border: 1px solid #222;
                  thead {
                    background: #222;
                    display: block;
                    tr {
                      th {
                        color: var(--whiteClr);
                        font-size: 12px;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 150px;
                        border: 0;
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                      }
                    }
                  }
                  tbody {
                    display: block;
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 360px;
                    &::-webkit-scrollbar {
                      width: 6px;
                      height: 6px;
                    }
                    &::-webkit-scrollbar-track {
                      background: #222;
                    }
                    &::-webkit-scrollbar-thumb {
                      background: #333;
                      &:hover {
                        background: #444;
                      }
                    }
                    tr {
                      td {
                        color: var(--whiteClr);
                        font-size: 11px;
                        border-color: #333;
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                        span {
                          color: var(--bodyClr);
                          width: 35px;
                          text-align: center;
                          display: inline-block;
                          border-radius: 4px;
                          &.lay {
                            background: #72bbef;
                          }
                          &.back {
                            background: #faa8ba;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .no_data {
              color: var(--whiteClr);
              font-size: 20px;
              font-weight: 600;
              width: 100%;
              text-align: center;
            }
          }
          &.live_tv {
            .accordion-body {
              background: var(--bodyClr);
              .live_tv_box {
                height: 215px;
              }
            }
          }
          &.bets {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .match_slider_sec {
    background: #080808;
    width: 100%;
    margin: 0 auto;
    .match-icon {
      padding: 0;
      margin-bottom: 0;
      .slick-list {
        padding: 0 35px;
      }
      div {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 0;
          color: var(--whiteClr);
          height: 48px;
          background: #393939;
          margin: 12px 8px;
          padding: 2px;
          border-radius: 12px;
          @media (max-width: 767.98px) {
            height: 60px;
            flex-direction: column;
            justify-content: space-between;
            padding: 8px 5px;
            margin: 8px 7px;
          }
          &.newworldcup {
            img {
              width: 14px;
              height: 28px;
            }
          }
          p {
            margin-bottom: 0;
            font-size: 15px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          img {
            width: 22px;
            height: 22px;
            margin-right: 8px;
          }
        }
      }
      .slick-arrow {
        background-color: #191919;
        z-index: 99;
        width: 30px;
        height: 100%;
        border-radius: 0;
        display: flex !important;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 0 5px 0 rgb(0 0 0 / 35%);
        &.slick-prev {
          left: 0;
          &::before {
            content: "";
            background-image: url(./assets/images/icons/leftarrow.png);
            width: 16px;
            height: 16px;
            display: block;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            margin: 0;
          }
        }
        &.slick-next {
          right: 0;
          &::before {
            content: "";
            background-image: url(./assets/images/icons/rightarrow.png);
            width: 16px;
            height: 16px;
            display: block;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            margin: 0;
          }
        }
      }
      ul {
        display: flex;
        justify-content: center;
        border-radius: 5px;
        overflow-x: scroll;
        // background: var(--headClr);

        overflow-y: hidden;
        align-items: center;
        padding: 10px 15px;
        margin-bottom: 8px;
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          background: #222;
        }
        &::-webkit-scrollbar-thumb {
          background: #333;
          &:hover {
            background: #444;
          }
        }
      }
    }
    .match_info_slider {
      .info_box {
        // background: var(--headClr);
        // background: linear-gradient(
        //   50deg,
        //   rgb(34 42 55) 0%,
        //   rgb(52 59 70) 50%,
        //   rgb(36 42 55) 100%
        // );
        background-image: url(./assets/images/cricketinfo.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 128px;
        padding: 8px;
        margin: 0 3px;
        border-radius: 10px;
        .match {
          text-align: center;
          font-size: 10px;
          line-height: 12px;
          display: flex;
          justify-content: space-between;
          .tournament {
            color: #aa9d9d;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            font-weight: 600;
            &.series-name {
              color: #fff;
            }
          }
          .time {
            color: var(--whiteClr);
            font-weight: 500;
          }
        }
        .participants {
          display: flex;
          position: relative;
          margin: 4px;
          color: var(--whiteClr);
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          .time {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            span {
              color: #9d9da1;
              font-weight: 100;
              font-size: 14px;
              line-height: 18px;
            }
            span.match-time {
              color: #fff;
              font-weight: bold;
            }
          }
          .item {
            flex: 1;
            overflow: hidden;
            font-size: 10px;
            text-align: center;
            .flag {
              width: 32px;
              height: 32px;
              line-height: 28px;
              margin: 0 auto;
              border-radius: 50%;
            }
            .name {
              line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 14px;
              margin-top: 8px;
              font-weight: 600;
            }
          }
        }
        .market {
          display: flex;
          gap: 4px;
          .outcome {
            background: #373d57;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            flex: 1;
            display: flex;
            justify-content: space-between;
            padding: 8px;
            border-radius: 4px;
            .name {
              color: #abb0ba;
            }
            .odds {
              color: #e7cf90;
            }
          }
        }
      }
      .slick-arrow {
        display: none !important;
        background: #222430;
        height: 100%;
        &.slick-prev {
          border-radius: 8px 0 0 8px;
        }
        &.slick-next {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }
  .popular_game_sec {
    background: transparent;
    padding: 2rem;
    margin-bottom: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    // box-shadow: 0 4px 3px rgba(255, 189, 0, 0.6);
    @media screen and (max-width: 767.98px) {
      padding: 1rem 2rem 0.5rem;
    }
    .head-popular {
      font-weight: 600;
      color: var(--primaryClr);
      // font-family: Proza Libre, sans-serif;
      font-size: 24px;
    }
    .game_box {
      margin: 5px;
      a {
        img {
          border-radius: 0.75rem;
        }
      }
    }
  }
  .sports_banner_sec {
    display: none;
    // background-color: var(--headClr);
    // background-image: url(./assets/images/banner/bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 0 2rem;
    margin-bottom: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    @media screen and (max-width: 767.98px) {
      padding: 0.5rem;
      border-radius: 0.5rem;
      margin-bottom: 0;
    }
    .img_box {
      img {
        border-radius: 0.5rem;
      }
    }
  }
  .casino_sec {
    // background-color: var(--headClr);
    background: url(./assets/images/banner/bgr2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 2rem;
    margin-bottom: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    @media screen and (max-width: 1199.98px) {
      padding: 0.8rem;
    }
    .heading {
      min-width: 20%;
      @media screen and (max-width: 767.98px) {
        margin-bottom: 1rem !important;
      }
      h5 {
        font-weight: 600;
        color: var(--primaryClr);
        // font-family: Proza Libre, sans-serif;
        font-size: 24px;
      }
      h6 {
        font-weight: 500;
        font-size: 15px;
      }
      .btn {
        background-color: var(--primaryClr);
        color: var(--whiteClr);
        font-size: 16px;
        width: 90%;
        min-width: 170px;
        border-radius: 40px;
        @media screen and (max-width: 1199.98px) {
          width: 100%;
        }
        &.casino_btn,
        &.exchange_btn {
          background: var(--secondaryClr) !important;
          border-color: var(--secondaryClr);
          color: var(--whiteClr);
        }
      }
    }
    .games {
      overflow: hidden;
      a {
        img {
          max-width: 95%;
          border-radius: 0.75rem;
        }
      }
    }
  }
  .providers_sec {
    background-color: #2b2b2b;
    padding: 12px 8px;
    display: none;
    // margin-bottom: 2.5rem;
    // border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    @media screen and (max-width: 1199.98px) {
      padding: 1rem;
    }
    .heading {
      max-width: 300px;
      text-align: center;
      @media screen and (max-width: 1199.98px) {
        margin: 0 auto;
      }
      p {
        color: var(--primaryClr);
      }
    }
    .providers_logo {
      width: 100%;
      @media screen and (max-width: 1199.98px) {
        width: calc(100% - 0px);
      }
      .logo_box {
        margin: 3px;
        img {
          max-width: 120px;
          padding: 3px;
          border-radius: 10px;
          filter: grayscale(100%);
          &:hover {
            filter: inherit !important;
          }
        }
      }
    }
  }
  .TopCategories {
    margin: 0 0px 30px;
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;
      margin: 0;
      padding: 0;
      background: var(--secondaryClr);
      list-style: none;
      overflow-x: auto;
      border: 0px;
      margin-bottom: 20px;
      .nav-item {
        button,
        .anav-link {
          background-size: 30px 30px !important;
          background-position: 27px 10px !important;
          background-repeat: no-repeat !important;
          color: var(--whiteClr);
          font-size: 14px;
          width: 92px;
          height: 100%;
          padding: 46px 5px 5px;
          border: 0;
          border-radius: 0;
          &.active {
            background: var(--bodyClr);
          }
          &#uncontrolled-tab-example-tab-Roulette {
            background-image: url("https://gbet567.com/images/Roulette_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Baccarat {
            background-image: url("https://gbet567.com/images/Baccarat_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Andar\ Bahar {
            background-image: url("https://gbet567.com/images/Andar_bahar_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Poker {
            background-image: url("https://gbet567.com/images/Poker_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Blackjack {
            background-image: url("https://gbet567.com/images/Blackjack_icons.png");
          }
          &#uncontrolled-tab-example-tab-TeenPatti {
            background-image: url("https://gbet567.com/images/Teenpatti.png");
          }
          &#uncontrolled-tab-example-tab-32Card {
            background-image: url("https://gbet567.com/images/casino.png");
          }
          &#uncontrolled-tab-example-tab-Seven {
            background-image: url("https://gbet567.com/images/seven.png");
          }
          &#uncontrolled-tab-example-tab-Dices {
            background-image: url("https://gbet567.com/images/dices.png");
          }
          &#uncontrolled-tab-example-tab-Sports {
            background-image: url("../public/images/sportsss.png");
          }
        }
      }
    }
  }
  .mainBanner {
    &.afterloginbanner {
      img {
        height: 370px !important;
        max-height: 370px !important;
      }
    }
    .slick-slide {
      img {
        width: 100%;
        height: 110vh;
        max-height: 110vh;
        margin-bottom: -7px;
        object-fit: cover;
      }
      .content {
        bottom: auto;
        font-size: 0.85rem;
        left: 0;
        position: absolute;
        right: auto;
        text-align: left;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 100%;
        z-index: 99;
        h2 {
          @media screen and (min-width: 768px) {
            font-size: 2rem;
          }
          @media screen and (min-width: 1024px) {
            font-size: 3.5rem;
          }
          @media screen and (max-width: 767px) {
            font-size: 1.15rem;
          }
          span {
            color: var(--primaryClr);
            margin: 0 8px;
          }
        }
        p {
          @media screen and (max-width: 767px) {
            font-size: 0.7rem;
          }
        }
      }
    }
    .slick-dots {
      bottom: 65px;
      text-align: right;
      right: 95px;
      li {
        margin: 0 2px;
        padding: 0;
        &.slick-active button:before {
          background: var(--primaryClr) !important;
        }
        button:before {
          font-size: inherit;
          background: #fff;
          width: 20px;
          height: 4px;
          border-radius: 8px;
          opacity: 1;
        }
      }
    }
  }
  .sectionTitle {
    font-size: 18px;
    font-weight: normal;
    color: var(--whiteClr);
    position: relative;
    display: inline-block;
    margin: 0 0 10px;
    padding-bottom: 5px;
    &:after {
      content: " ";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50%;
      height: 1px;
      background: var(--primaryClr);
    }
  }
  .innerBanner {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    .link {
      width: 100%;
      background: var(--primaryClr);
      font-size: 11px;
      font-weight: 500;
      display: block;
      text-align: center;
      color: var(--whiteClr);
      text-transform: uppercase;
      padding: 8px;
    }
  }
  .payment-info {
    // background: #2b2b2b;
    // margin: 0 -1rem;
    padding: 22px 1rem;
    color: var(--whiteClr);
    .balance {
      font-size: 16px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 14px;
        fill: var(--whiteClr);
        stroke: var(--whiteClr);
        opacity: 0.6;
      }
    }
    .balance-amount {
      text-align: right;
      font-weight: bold;
      font-size: 16px;
    }
    .btn {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      &.btn-outline-secondary {
        border-color: var(--whiteClr);
        color: var(--whiteClr);
      }
    }
  }
  .account-statement {
    input {
      z-index: 9 !important;
      border: none;
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      border-radius: 5px;
      padding: 14px 15px;
      outline: none;
      color: #8a92ab !important;
      background: #191919 !important;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
    button.calender-icon {
      position: absolute;
      right: 0;
      svg {
        opacity: 0;
        @media only screen and (max-width: 767px) {
          opacity: 1;
        }
      }
    }
    button.calender-icon {
      svg {
        opacity: 0;
        @media only screen and (max-width: 767px) {
          opacity: 1;
        }
      }
    }
    .search-icon {
      button {
        svg {
          display: block;
        }
      }
    }
    button {
      position: absolute;
      right: 0;
      width: 40px;
      height: 33px;
    }
    h3 {
      border-bottom: 1px solid rgba(138, 146, 171, 0.3);
      font-size: 16px;
      font-weight: 300;
      margin: 12px -1rem 22px -1rem;
      padding: 0 1rem 12px 1rem;
    }
    .btn-primary {
      border-radius: 0;
      padding: 6px 10px;
    }
    ul {
      background-color: #2b2b2b;
      max-height: 622px;
      overflow-y: scroll;
      span {
        display: flex;
        background: #191919;
        box-shadow: 0px 1px 0px #65739c;
        padding: 20px;
        li {
          border: 0;
          background: #191919;
          font-size: 14px;
          padding: 0;
          font-weight: 600;
          color: var(--whiteClr);
          text-align: left;
          width: 16%;

          &:first-child {
            width: 14%;
          }
          &:nth-child(2) {
            width: 26%;
          }
          &:nth-child(3) {
            width: 18%;
          }
          &:nth-child(4) {
            width: 14%;
          }
          &:nth-child(6) {
            width: 12%;
          }
        }
      }
      li {
        border-bottom: 1px solid rgba(138, 146, 171, 0.3);
        background-color: #2b2b2b;
        padding: 20px 20px;
        .casinoBetsDetails {
          display: flex;
          .h4 {
            font-size: 14px;
            margin-bottom: 0;
            width: 14%;
          }
          .h5 {
            margin-bottom: 0;
            font-size: 12px;
            letter-spacing: 0.86px;
            width: 14%;
          }
          .details {
            font-size: 12px;
            font-weight: 300;
            width: 26%;
            background: transparent;
            border: 0 !important;
            box-shadow: none;
            padding: 0;
          }
          .statue {
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0.7px;
            width: 16%;
            background-color: transparent;
            box-shadow: none;
            padding: 0;
          }
          small {
            font-size: 12px;
            font-weight: 300;
            display: block;
            width: 18%;
            &.balance {
              width: 12%;
            }
          }
        }
      }
    }
    table {
      thead {
        background: #283968;
        box-shadow: 0px 1px 0px #65739c;
        tr {
          th {
            color: #fff;
            white-space: nowrap;
            padding: 20px;
            border: 0;
          }
        }
      }
      tbody {
        border-bottom: 1px solid rgba(138, 146, 171, 0.3);
        background-color: #283352;

        color: #fff;
        tr {
          td {
            color: #fff;
            white-space: nowrap;
            padding: 20px 20px;
          }
        }
      }
    }
  }
  .PageSection {
    display: flex;
    margin-top: 1.5rem;
    gap: 20px;
    &.beforepromotion {
      .PageRightBar {
        width: 100% !important;
      }
    }
    &.promoContent .PageRightBar {
      width: 100% !important;
      .RightBarHead {
        background-color: var(--whiteClr);
        color: var(--primaryClr);
        .back-link {
          border: 1px solid var(--primaryClr);
          color: var(--primaryClr);
          &:hover {
            background-color: var(--primaryClr);
            color: var(--whiteClr);
          }
        }
      }
      .PromoBannerPage {
        background-color: var(--whiteClr);
        padding: 12px;
        margin-bottom: 0 !important;
      }
      .promosection {
        background-color: var(--whiteClr);
        padding: 15px 40px;
        &.LossBonus {
          width: 100%;
          display: block;
          .right-promo {
            width: 100%;
            text-align: center;
            img {
              width: 300px;
            }
          }
          .left-promotion {
            width: 100%;
          }
        }
        h3 {
          color: var(--primaryClr);
        }
        p {
          color: var(--blackClr);
          span {
            color: var(--primaryClr);
          }
        }
      }
    }
    .PageLeftBar {
      width: 30%;
      .LeftBarSide {
        background: #2b2b2b;
        border-radius: 10px;
        padding: 20px 20px 20px;

        ul {
          @media only screen and (max-width: 767.98px) {
            display: flex;
            gap: 15px;
            overflow-x: scroll !important;
            white-space: nowrap;
            margin-bottom: 0 !important;
          }
          li a {
            background: #191919;
            border-radius: 10px;
            display: flex;
            align-items: center;
            padding: 16px 20px 20px;
            gap: 10px;
            transition: all 0.4s;
            color: var(--whiteClr);
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 15px;
            @media only screen and (max-width: 767.98px) {
              margin-bottom: 10px;
            }
            &:hover {
              background-color: var(--primaryClr);
              color: #ffffff;
            }
            &:active {
              background-color: var(--primaryClr);
              color: #ffffff;
            }
            img {
              width: 20px;
            }
          }
        }
      }
    }
    &.promotionPage {
      .PageRightBar {
        width: 100% !important;
      }
    }
    .PageRightBar {
      width: 70% !important;
      .RightBarHead {
        color: var(--whiteClr);
        background: #2b2b2b;
        padding: 20px 30px 27px;
        margin-bottom: 24px;
        h2 {
          margin: 0;
          font-weight: 600;
          font-size: 24px;
        }
      }
      .ProfileMainSec {
        display: flex;
        gap: 20px;
        .ProfileImgSec {
          background-color: #2b2b2b;
          padding: 15px 15px;
          border-radius: 10px;
          width: 30%;
          span {
            padding-bottom: 15px;
            font-size: 14px;
            color: #fff;
            font-weight: bold;
            display: block;
            text-align: center;
          }
        }
        .profile-img {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          padding-bottom: 20px;
          max-width: 120px;
          width: 120px;
          height: 120px;
          margin: 0 auto;
          text-align: center;
          margin-bottom: 6px;
          img {
            max-width: 120px;
            width: 120px;
            height: 120px;
            border-radius: 50%;
            // border: 2px solid var(--primaryClr);
          }
          span {
            margin-top: 12px;
            font-size: 18px;
            font-weight: 600;
          }
        }
        .edit-btn {
          background: #09ff8d;
          position: absolute;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          padding: 8px;
          top: 83px;
          border: 128px;
          right: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            fill: var(--whiteClr);
          }
        }
        .profile-info {
          table {
            margin-bottom: 0 !important;
          }
          th {
            background: var(--blackClr);
            color: var(--whiteClr);
          }
          td {
            background: var(--blackClr);
            color: var(--whiteClr);
            border-bottom: 1px solid rgba(138, 146, 171, 0.3);
          }
          .edit-btn {
            top: 0;
            right: 0;
            margin-left: auto;
          }
        }
        .reset-password {
          text-align: center;
          border-top: 1px solid rgba(138, 146, 171, 0.3);
          padding: 20px 0;
          .ResetPassword {
            font-size: 14px;
            font-weight: 600;
            color: var(--primaryClr);
            margin-bottom: 18px;
            display: block;
            cursor: text;
          }
        }
        .ProfileForm {
          width: 70%;
          // background: url(./assets/images/banner/abstract.png);
          background-color: #2b2b2b;
          border-radius: 10px;
          padding: 20px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          display: flex;
          justify-content: center;
          align-items: center;
          .profile-info {
            width: 100%;
          }
        }
      }
      .tabs-area {
        width: 100%;
        background-color: #2b2b2b;
        padding: 15px 15px;
        border-radius: 10px;
        ul {
          margin: 0 !important;
        }
        .upi-list {
          li {
            width: 5% !important;
          }
        }
      }
      .tabs-area {
        margin-top: 10px;
        ul {
          margin: 0 -1rem;
          flex-wrap: nowrap;
          li {
            width: 100%;
            &:not(:last-child) {
              border-right: 1px solid #545454;
            }
            .nav-link {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 100%;
              background: var(--bodyClr);
              color: rgba(255, 255, 255, 0.6);
              border-radius: 0;
              font-size: 16px;
              font-weight: bold;
              text-transform: uppercase;
              padding: 17px 10px;
              border: none;
              &.active {
                background: rgba(226, 0, 1, 0.9) !important;
                color: #fff !important;
              }
            }
          }
        }
        .tab-content {
          .bank-details {
            margin-top: 22px;
            background: var(--whiteClr);
            box-shadow: 5px 5px 10px #0000001a;
            border-radius: 10px;
            overflow: hidden;
            .top-area {
              background: var(--whiteClr);
              width: 100%;
              font-size: 14px;
              padding: 20px;
            }
            .bottom-area {
              padding: 20px;
              table {
                margin-bottom: 0;
                th {
                  font-size: 18px;
                  font-weight: 500;
                  padding: 5px 0;
                }
                td {
                  font-size: 14px;
                  font-weight: 300;
                  padding: 5px 0;
                  width: 50%;
                }
              }
            }
          }
          form {
            padding: 55px 30px 0;
            text-align: center;
            input {
              width: 100%;
              background: transparent;
              border-radius: 10px;
              padding: 15px 10px 15px;
              outline: none;
              border: 1px solid #fff;
              color: var(--whiteClr) !important;
              font-size: 12px;
              &::placeholder {
                color: #858b9d !important;
              }
              &.form-check-input {
                border-radius: 50px;
                width: 24px;
                height: 24px;
                border: 2px solid var(--primaryClr);
                padding: 0;
                background-color: var(--whiteClr);
                position: relative;
                &:checked:after {
                  content: "";
                  background: var(--headClr);
                  border-radius: 50px;
                  width: 16px;
                  height: 16px;
                  display: block;
                  position: absolute;
                  top: 2px;
                  left: 2px;
                }
              }
            }
            .btn {
              padding: 9px 30px;
              font-size: 14px;
              text-transform: uppercase;
              min-width: 120px;
              margin-right: 10px;
              margin-bottom: 10px;
            }
            .radio-btns {
              display: flex;
              .form-check {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 300;
                .form-check-label {
                  margin-left: 5px;
                  margin-top: 3px;
                }
              }
            }
          }
          .card-holder {
            height: 115px;
            background: var(--whiteClr);
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 5px 5px 10px #0000001a;
            img {
              max-height: 40px;
            }
          }
        }
        .h4 {
          font-size: 14px;
          font-weight: 500;
        }
        .sectionTitle {
          &::after {
            background: var(--headClr);
            width: 100%;
          }
        }
      }
      .promosection {
        display: flex;
        align-items: center;
        gap: 25px;
        .left-promotion {
          width: 65%;
          h5 {
            color: var(--primaryClr);
            margin-top: 28px;
            font-size: 20px;
          }
          ul {
            li {
              padding-left: 15px;
              color: var(--blackClr);
              position: relative;
              &::after {
                content: "";
                position: absolute;
                background-color: var(--blackClr);
                width: 5px;
                height: 5px;
                border-radius: 50%;
                left: 0;
                top: 9px;
              }
            }
          }
        }
        .right-promo {
          width: 35%;
          img {
            width: 95%;
          }
        }
      }
    }
  }
}

.listings {
  .filter {
    margin-bottom: 20px;

    .dropdown-toggle {
      background: var(--primaryClr) !important;
      border: none !important;
      border-radius: 0;
      padding: 5px 15px;
    }
    .btn-outline-primary {
      width: 100%;
      padding: 5px;
      background: transparent;
      border-radius: 0 !important;
    }
    .dropdown-menu {
      border-radius: 0;
      border: 1px solid var(--whiteClr);
      width: 100% !important;
      white-space: pre-wrap;
      min-width: auto;
      padding: 0 !important;
      margin-top: -3px;
      a {
        border-bottom: 1px solid var(--whiteClr);
        color: var(--headClr);
        font-size: 14px;
        opacity: 0.8;
        padding: 13px 18px;
        &:last-child {
          border: none;
        }
      }
    }
  }
  .like-btn {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 9px;
    border-radius: 0 0 7px 0;
    svg {
      fill: var(--whiteClr);
    }
  }
}

.steps-canvas {
  &.LoginImg {
    // background: rgb(127, 0, 0);
    // background: linear-gradient(
    //   135deg,
    //   rgba(127, 0, 0, 1) 1%,
    //   rgba(127, 0, 0, 1) 40%,
    //   rgba(0, 0, 0, 1) 100%
    // );
    width: 100%;
    .form-steps {
      padding: 0 30px 0;
      height: 100vh;
      display: flex;
      flex-direction: column;
      img {
        width: 60%;
        margin: 0 auto 55px;
      }
      h4 {
        text-transform: uppercase;
        font-size: 15px;
        text-align: left;
        margin-bottom: 20px;
        margin-top: 15px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 12%;
          height: 2px;
          background-color: var(--primaryClr);
          bottom: -5px;
          left: 0;
        }
      }
      form {
        input {
          // margin-bottom: 15px !important;
          background: transparent !important;
          border-color: #fff;
          height: 42px;
          border-radius: 6px;
          padding-left: 42px;
          position: relative;
          width: 100%;
          &::placeholder {
            color: #fff;
          }
        }
        span.input-icon {
          position: absolute;
          width: 36px;
          height: 32px;
          left: 0;
          z-index: 9;
          top: 4px;
          display: flex;
          border-right: 1px solid var(--whiteClr);
          align-items: center;
          img {
            margin-bottom: 0 !important;
          }
        }
        .form-link {
          color: var(--whiteClr);
          opacity: 1;
        }
        button.loginbtn {
          width: 100%;
          border-radius: 6px;
          font-size: 13px;
          height: 35px;
          background: var(--primaryClr) !important;
          border-color: var(--primaryClr) !important;
          padding: 0;
          // margin-top: 45px;
        }
        .flag-dropdown {
          background-color: transparent !important;
          border: 0;
          border-right: 1px solid var(--whiteClr) !important;
          .selected-flag:hover {
            background-color: transparent !important;
          }
        }
      }
      .sign-up {
        font-size: 13px;
        margin-top: 65px !important;
        a {
          position: relative;
          border: 10px solid;
          border-image-slice: 1;
          border-width: 1px;
          background: none;
          border-image-source: linear-gradient(to left, #499b4c, #d84c48);
          text-decoration: inherit;
          width: 100%;
          border-radius: 6px;
          appearance: none;
          margin-top: 10px;
          display: flex;
          height: 35px;
          padding: 0;
          color: var(--whiteClr);
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          font-size: 13px;
        }
      }
    }
    &.SignUpImg::after {
      content: "";
      background: url(./assets/images/banner/signupimg.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    &::after {
      content: "";
      background: url(./assets/images/banner/loginimg.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 70%;
      height: 100%;

      position: absolute;
    }
  }
  .offcanvas-header {
    background: var(--headClr);
    width: 30%;
    margin-left: auto;
    .offcanvas-title {
      font-size: 20px;
      font-weight: bold;
      color: var(--whiteClr);
    }
  }
  .offcanvas-body {
    width: 30%;
    margin-left: auto;
    overflow: hidden;
  }
  .form-steps {
    padding: 55px 30px 0;
    .h2 {
      font-size: 28px;
      font-weight: 500;
      color: var(--whiteClr);
    }
    p {
      font-size: 18px;
      font-weight: 300;
      color: var(--whiteClr);
    }
    input {
      background: var(--headClr);
      border-radius: 10px;
      border-color: var(--headClr);
      padding: 14px 20px;
      color: var(--whiteClr);
    }
    .icon {
      position: absolute;
      top: 21px;
      right: 15px;
      transform: translate(0, -50%);
      fill: var(--whiteClr);
      img {
        width: 22px;
        margin: 0 !important;
      }
    }

    .btn {
      padding: 9px 30px;
    }
    .form-link {
      font-size: 14px;
      color: var(--lightClr);
      &:hover {
        opacity: 0.7;
      }
    }
    .sign-up {
      font-size: 14px;
      font-weight: 500;
      a {
        font-size: 16px;
        color: var(--primaryClr);
        text-decoration: underline;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.transfer_info_page {
  .transfer_details {
    // background: url("./assets/images/deposit-bg.jpg") var(--secondaryClr);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    padding: 10px;
    border: 1px solid #434343;
    padding: 40px 40px !important;
    background: #2b2b2b;
    // border: 1px solid #333;
    border-radius: 10px;
    overflow: hidden;
    z-index: 9;
    h5 {
      margin-bottom: 30px;
      color: var(--whiteClr);
      font-size: 24px;
      font-weight: bold;
    }
    .bank-detailss {
      background: #434343;
      padding: 15px;
      border-radius: 10px;
      p {
        border-bottom: 1px solid rgba(138, 146, 171, 0.3);
        padding-bottom: 15px;
        margin-top: 15px;
        margin-bottom: 0 !important;
      }
    }
    form {
      padding: 0 !important;
      margin-top: 15px;
      .form-group {
        border-radius: 5px;
        .form-label {
          color: rgba(255, 255, 255, 0.7);
          margin-bottom: 3px;
        }
        .form-control {
          padding: 10px 15px;
          border: 0;
          border-top: 1px solid rgb(1, 63, 72, 0.35);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
            0 1px 2px 0 rgba(0, 0, 0, 0.12),
            inset 0 1px rgba(255, 255, 255, 0.04);
        }
      }
    }
  }
  .deposit_img {
    img {
      animation: moving 3s infinite linear;
      @keyframes moving {
        0% {
          left: 0;
        }
        50% {
          left: 0;
          -webkit-transform: translateY(15px);
          transform: translateY(15px);
        }
        100% {
          left: 0;
        }
      }
    }
  }
}

.upi-list {
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      margin: 0 7px;
      width: 50px;
      border: none;
    }
  }
}

.sports_page_banner {
  .slick-slider {
    img {
      border-radius: 5px;
      height: 220px;
      object-fit: cover;
    }
    .slick-dots {
      bottom: 8px;
      li {
        height: 3px;
        button {
          height: 3px;
          padding: 0;
          &:before {
            background: rgba(255, 255, 255, 0.7);
            color: transparent;
            width: 25px;
            height: 3px;
            opacity: 1;
          }
        }
        &.slick-active {
          button {
            &:before {
              background: var(--primaryClr);
            }
          }
        }
      }
    }
  }
}

.sports_page,
.custom-tabs {
  padding-top: 10px;
  .sports-name-listing {
    ul {
      flex-wrap: nowrap;
      padding: 10px;
      background: var(--secondaryClr);
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      li {
        margin: 0 5px;
        a,
        div {
          color: #fff;
          padding: 5px;
          display: block;
          cursor: pointer;
          &:hover,
          &.active {
            background: transparent;
            color: var(--primaryClr);
          }
        }
      }
    }
  }
  .nav {
    flex-wrap: nowrap;
    background: var(--headClr);
    // margin-bottom: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    .nav-item {
      margin: 0 5px;
      .nav-link {
        color: var(--whiteClr);
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px;
        border: none;
        border-radius: 0;
        &:hover,
        &.active {
          background: transparent;
          color: var(--primaryClr);
        }
      }
    }
  }
  .tab-pane {
    .title {
      background: var(--headClr);
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      display: none;
      @media only screen and (max-width: 767.98px) {
        margin-bottom: 0;
        justify-content: center;
      }
      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        color: var(--whiteClr);
        margin-left: -1rem;
      }
    }
    .game-listing {
      background: var(--secondaryClr);
      border: 1px solid var(--headClr);
      border-radius: 0 0 0.5rem 0.5rem;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);

      .match-listing-head {
        display: none;
        ul {
          background-color: #ced5da;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          li:first-child {
            width: calc(100% - 300px);
            display: block;
          }
          li {
            width: 48px;
            color: #000;
            padding: 5px 3px;
            font-size: 12px;
          }
        }
      }
      .match-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px;
        border-bottom: 1px solid rgba(255, 184, 12, 0.1);
        background: var(--secondaryClr);
        // height: 25px;
        &:last-child {
          border-bottom: 0;
          border-radius: 0 0 5px 5px;
        }
        .live-match {
          height: 8px;
          width: 8px;
          margin-right: 5px;
          background: #c4cfd6;
          border: 1px solid #366601;
          border-radius: 50%;
        }
        .live-match.active {
          background: #6aba12;
          border: 1px solid #366601;
        }
        .match-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: calc(100% - 315px);
          padding: 5px 0;
          .breadcrumb {
            font-size: 12px;
            display: flex;
            align-items: center;
            font-weight: 700;
            color: var(--whiteClr);
            cursor: pointer;
            .date_time_info {
              background: #36404d;
              width: 68px;
              height: 42px;
              overflow: hidden;
              margin-right: 10px;
              padding: 6px 1px;
              border-radius: 5px;
              position: relative;
              .date {
                color: var(--whiteClr);
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                text-align: center;
                text-transform: uppercase;
              }
              &.in-play {
                background: var(--headClr);
                .date {
                  color: var(--primaryClr) !important;
                }
                span {
                  position: absolute;
                  background: linear-gradient(
                    to right,
                    #171618,
                    var(--primaryClr)
                  );
                  &:nth-child(1) {
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate1 2s linear infinite;
                    @keyframes animate1 {
                      0% {
                        transform: translateX(-100%);
                      }
                      100% {
                        transform: translateX(100%);
                      }
                    }
                  }
                  &:nth-child(2) {
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate2 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate2 {
                      0% {
                        transform: translateY(-100%);
                      }
                      100% {
                        transform: translateY(100%);
                      }
                    }
                  }
                  &:nth-child(3) {
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate3 2s linear infinite;
                    @keyframes animate3 {
                      0% {
                        transform: translateX(100%);
                      }
                      100% {
                        transform: translateX(-100%);
                      }
                    }
                  }
                  &:nth-child(4) {
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate4 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate4 {
                      0% {
                        transform: translateY(100%);
                      }
                      100% {
                        transform: translateY(-100%);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .status {
          background: var(--oddsBack);
          color: var(--blackClr);
          display: flex;
          padding: 0 3px;
          font-size: 11px;
          font-style: italic;
          font-weight: 600;
          border-radius: 2px;
          align-items: center;
          justify-content: center;
        }
        .listing {
          display: flex;
          flex-wrap: nowrap;
          li {
            margin-right: 5px;
            width: 45px;
            height: 30px;
            &:nth-child(2),
            &:nth-child(4) {
              margin-right: 10px;
            }
            &:last-child {
              margin-right: 0;
            }
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              padding: 0 2px;
              cursor: pointer;
              height: 30px;
              line-height: 30px;
              border-radius: 5px;
              span {
                color: var(--blackClr);
                font-size: 12px;
                font-weight: 700;
              }
              &.yes {
                background: var(--oddsBack);
              }
              &.no {
                background: var(--oddsLay);
              }
            }
          }
        }
      }
    }
    .top-row {
      display: flex;
      justify-content: space-between;
      background: var(--headClr);
      margin: 20px -1rem;
      padding: 0 1rem;
      .bet-btns {
        display: flex;
        li {
          background: var(--primaryClr);
          color: var(--whiteClr);
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }
    .status-btns {
      display: flex;
      align-items: center;
      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--whiteClr);
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
      }
    }
    .game-points {
      ul {
        margin: 0 -1rem;
        li {
          display: flex;
          border-bottom: 1px solid var(--secondaryClr);
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;
          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;
            .time {
              color: var(--siteRedClr);
            }
          }
          .score {
            margin-left: auto;
          }
          .status-btns {
            margin-left: 5px;
            position: relative;
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;
              span {
                font-size: 12px;
              }
            }
            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: var(--whiteClr);
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .game-top-area {
      background: var(--headClr);
      margin: 0 -1rem;
      padding: 10px 1rem;
      .date {
        text-align: center;
        color: var(--primaryClr);
        font-size: 12px;
        margin-bottom: 5px;
      }
      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
      .range {
        background: var(--primaryClr);
        font-size: 11px;
        color: var(--blackClr);
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.sports_page {
  .tab-content {
    .tab-pane {
      max-height: calc(100vh - 120px);
      padding-bottom: 10px;
      overflow-y: auto;
    }
  }
}
.match_details_container {
  background: #101010;
  padding-top: 15px;
  .math_info {
    text-align: center;
    .match_type {
      background: linear-gradient(
        to right,
        #f7a204 0,
        #ffc230 15%,
        #ffc230 30%,
        #f7a204 55%,
        #f7a204 100%
      );
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      text-shadow: 1px 1px 3px rgb(0 0 0 / 50%);
      word-spacing: 3px;
      width: fit-content;
      height: 34px;
      line-height: 34px;
      padding: 0 15px;
      margin: 0 auto;
      border-radius: 25px;
      position: relative;
      z-index: 1;
      animation: shine 3s linear infinite;
      @keyframes shine {
        0% {
          -webkit-box-shadow: 0 0 0 0 var(--primaryClr);
          box-shadow: 0 0 0 0 var(--primaryClr);
        }
        40% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        80% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        100% {
          -webkit-box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
        }
      }
      &:after {
        position: absolute;
        content: "";
        top: 16px;
        left: -40%;
        background: var(--primaryClr);
        width: 180%;
        height: 2px;
        z-index: -1;
        @media screen and (max-width: 767px) {
          width: 140%;
          left: -20%;
        }
      }
    }
    .team_names {
      margin: 15px 0 10px;
      span {
        color: var(--primaryClr);
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        @media screen and (max-height: 575.98px) {
          font-size: 13px;
        }
        span {
          color: rgb(237, 33, 58);
          font-size: 12px;
          @media screen and (max-height: 575.98px) {
            font-size: 10px;
          }
        }
        &:last-child {
          span {
            display: none;
          }
        }
      }
    }
    .collapse_container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      .btn {
        background: linear-gradient(
          to right,
          #1186d3 0,
          #41aaf0 15%,
          #41aaf0 30%,
          #1186d3 55%,
          #1186d3 100%
        ) !important;
        border: 0;
        border-radius: 5px;
        padding: 4px 6px;
        &:hover,
        &:focus {
          background: linear-gradient(
            to right,
            #1186d3 0,
            #41aaf0 15%,
            #41aaf0 30%,
            #1186d3 55%,
            #1186d3 100%
          ) !important;
        }
        img {
          width: 14px;
        }
      }
      .in_play {
        background: #111;
        color: #41aaf0;
        text-transform: uppercase;
        letter-spacing: 2px;
        overflow: hidden;
        font-weight: 700;
        margin: 0 15px;
        padding: 2px 6px;
        position: relative;
        .anim_line1 {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to right, #171618, #1186d3);
          animation: animate1 2s linear infinite;
        }
        .anim_line2 {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to bottom, #171618, #1186d3);
          animation: animate2 2s linear infinite;
          animation-delay: 1s;
        }
        .anim_line3 {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to left, #171618, #1186d3);
          animation: animate3 2s linear infinite;
        }
        .anim_line4 {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to top, #171618, #1186d3);
          animation: animate4 2s linear infinite;
          animation-delay: 1s;
        }
      }
    }
  }
  .sports-match-iframe {
    height: 100%;
    @media screen and (min-width: 1400px) {
      height: 390px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1399.98px) {
      height: 330px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1199.98px) {
      height: 361px;
    }
    @media screen and (min-width: 992px) and (max-width: 1023.98px) {
      height: 311px;
    }
    @media screen and (min-width: 880px) and (max-width: 991.98px) {
      height: 391px;
    }
    @media screen and (min-width: 768px) and (max-width: 879.98px) {
      height: 361px;
    }
    @media screen and (min-width: 650px) and (max-width: 767.98px) {
      height: 321px;
    }
    @media screen and (min-width: 576px) and (max-width: 649.98px) {
      height: 291px;
    }
    @media screen and (min-width: 480px) and (max-width: 575.98px) {
      height: 381px;
    }
    @media screen and (min-width: 420px) and (max-width: 479.98px) {
      height: 350px;
    }
    @media screen and (min-width: 380px) and (max-width: 419.98px) {
      height: 330px;
    }
    @media screen and (min-width: 360px) and (max-width: 379.98px) {
      height: 310px;
    }
    @media screen and (min-width: 320px) and (max-width: 359.98px) {
      height: 290px;
    }
    @media screen and (max-width: 319.98px) {
      height: 280px;
    }
  }
}
.page-details {
  .game-points {
    padding-bottom: 0;
  }
  .top-row {
    display: flex;
    justify-content: space-between;
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 0.5rem 1rem;
    ul.bet-btns {
      display: flex;
      li {
        background: var(--primaryClr);
        color: var(--blackClr);
        margin: 4px 0px 4px 4px;
        text-transform: uppercase;
        font-size: 11px;
        padding: 4px 8px;
        border-radius: 2px;
      }
    }
  }
  .game-top-area {
    background: #222;
    margin-bottom: 0px;
    padding: 5px 1rem 5px 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .date {
      text-align: center;
      color: var(--whiteClr);
      font-size: 12px;
      margin-bottom: 5px;
      order: 2;
      margin-bottom: 0;
    }
    .title {
      background: transparent;
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: 30px;
      color: #ffffff;
      font-weight: bold;
    }
    .range {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      float: none;
      padding: 0 6px;
      margin: 7px 0;
      background: var(--primaryClr);
      font-size: 12px;
      line-height: 16px;
      border-radius: 3px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .title {
    background: var(--headClr);
    padding: 0 1px 0 1rem;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 767.98px) {
      background-color: #3b5160;
      margin-bottom: 0;
      margin-top: 0 !important;
      padding: 0 0.25rem 0 0.5rem;
    }
    span {
      font-size: 14px;
      padding: 6px 0;
      display: inline-block;
      color: var(--whiteClr);
    }
    .status-btns {
      display: flex;
      align-items: center;
      @media screen and (max-width: 767.98px) {
        width: 136px;
      }
      .info-block {
        font-size: 13px;
        font-weight: 600;
        color: var(--blackClr);
        text-align: center;
        width: 68px;
        height: 26px;
        line-height: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2px 1px 1px;
        padding: 3px 2px;
        border-radius: 2px;
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
        @media screen and (max-width: 767.98px) {
          width: 50%;
        }
      }
    }
  }
  .game-points {
    border: 1px solid var(--headClr);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);
    padding-bottom: 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    ul {
      li {
        display: flex;
        border-bottom: 1px solid var(--secondaryClr);
        padding: 1px 0 1px 0.75rem;
        align-items: center;
        .score {
          margin-left: auto;
          margin-right: 5px;
        }
        .team-name {
          font-size: 12px;
          font-weight: 700;
          line-height: normal;
          display: flex;
          flex-direction: column;
          .time {
            color: var(--siteRedClr);
          }
        }
        .status-btns {
          display: flex;
          align-items: center;
          position: relative;
          .points-status {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            text-shadow: 0 0.26667vw 1.06667vw rgb(0 0 0 / 50%);
            font-size: 12px;
            color: var(--primaryClr);
            text-align: center;
            border-radius: 5px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &::after {
              position: absolute;
              top: 7px;
              left: 5px;
              border: 1px solid var(--primaryClr);
              content: "";
              padding: 10px;
              width: calc(100% - 10px);
              height: 24px;
              border-radius: 5px;
            }
          }
          &.match_odds {
            .info-block {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(5),
              &:nth-child(6) {
                background: #e9e9e9;
              }
            }
          }
        }
        .info-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 8px;
          line-height: normal;
          font-size: 10px;
          margin: 2px 1px 1px;
          padding: 5px 2px;
          width: 68px;
          text-align: center;
          color: var(--blackClr);
          cursor: pointer;
          border-radius: 4px;
          &.yes {
            background: var(--oddsBack);
          }
          &.no {
            background: var(--oddsLay);
          }
          span {
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.e-sports-icons {
  ul {
    display: flex;
    margin-top: 20px;
    gap: 0;
    justify-content: space-around;
    li {
      width: 10%;
    }
  }
}
.footer {
  padding: 0 0 10px;
  margin-top: 20px;
  // background-color: var(--headClr);
  background: url(./assets/images/background.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 20px 0 0;
  .providers_img {
    text-align: center;
    a {
      display: inline-block;
      padding: 0 5px;
      img {
        filter: contrast(0.1);
      }
    }
  }
  .helpline {
    background: #222;
    max-width: 300px;
    text-align: center;
    padding: 10px;
    margin: 10px auto;
    border: 1px solid #333;
    border-radius: 10px;
    h3 {
      font-size: 21px;
      font-weight: 700;
    }
    a {
      color: var(--primaryClr);
      font-size: 15px;
      font-weight: 700;
    }
  }
  p {
    color: var(--whiteClr);
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    justify-content: start;
    justify-content: center;
    display: flex;
    margin-top: 20px;
    margin-bottom: 0;
    background: #393939;
    height: 45px;
    display: flex;
    align-items: center;
  }
  .betfair {
    max-width: 110px;
    height: auto;
    filter: invert(1);
  }
  .footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
  .paymentImages {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        padding: 8px;
        img {
          max-height: 46px;
        }
      }
    }
  }
  .footer-links {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .FooterPart {
      flex: 1 1 auto;
      h3 {
        font-size: 14px;
        color: var(--whiteClr);
      }
      ul {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-top: 10px;
        li {
          border: 0;
          color: hsla(0, 0%, 100%, 0.6);
          font-size: 12px;
          font-weight: 300;
          padding-bottom: 12px;
          a {
            color: hsla(0, 0%, 100%, 0.6);
            font-size: 13px;
            img {
              width: 22px;
              margin-right: 9px;
            }
          }
        }
      }
    }
    ul {
      text-align: center;
      padding: 7px 0;

      margin-top: 10px;
      li {
        font-size: 12px;
        font-weight: 300;
        display: inline-block;
        border-right: 1px solid #596372;
        &:last-child {
          margin-right: 0;
          border-right: 0;
          &::after {
            display: none;
          }
        }
        a {
          color: #fff;

          font-size: 16px;
          &:hover,
          &:focus {
            color: #5c6060;
          }
        }
      }
    }
  }
  .social-icons {
    padding-top: 25px;
    ul {
      display: flex;
      justify-content: center;
      li {
        margin-right: 15px;
        position: relative;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 40px;
          height: auto;
          cursor: pointer;
        }
        svg {
          width: 32px;
        }
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .footer-menu {
    background: var(--bodyClr);
    width: 100%;
    // margin-left: 2%;
    // margin-bottom: 4px;

    z-index: 97;
    ul {
      li {
        text-transform: uppercase;
        font-size: 10px;
        flex: 1;
        &.active-foot {
          margin-top: -22px;
          padding-top: 8px;
          width: 68px;
          border-radius: 50%;
          height: 68px;

          a {
            padding: 0;
            background: #201e1f !important;
            width: 65px;
            border: 3px solid #131212;
            height: 65px;
            border-radius: 50%;
            margin: 0 auto;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            img {
              max-width: 65px !important;
              max-height: 65px !important;
            }
          }
        }
        a {
          padding: 8px 5px 10px;
          color: var(--whiteClr);
          display: flex;
          flex-direction: column;
          align-items: center;
          text-decoration: none;
          cursor: pointer;
          svg,
          img {
            fill: currentColor;
            max-width: 22px;
            max-height: 22px;
          }
          span {
            margin-top: 5px;
            font-size: 11px;
          }
          &.active {
            // color: var(--primaryClr);
            svg {
              fill: currentColor;
              stroke: currentColor;
            }
          }
        }
      }
    }
  }
}
.agent-head.crypto-head {
  background: transparent;
}
.agent-head {
  display: flex;
  flex-wrap: wrap;
  // background: #202a39;
  background: #434343;
  border-radius: 6px;
  padding: 10px;
  margin-top: 15px;
  .social-icon {
    width: 6%;
    margin: 0 1%;
    img {
      width: 100%;
    }
  }
}
.live_casino_page {
  // padding-top: 50px;

  // &.ezugi {
  //   background: rgb(35, 31, 32);
  // }
  &.live {
    background: var(--headClr);
    color: var(--whiteClr);
  }

  .header-bg {
    // background-color: #e3e2e2;
    .header {
      margin-bottom: 0;
    }

    .headerRight {
      fill: var(--primaryClr);
      .path-stroke {
        stroke: var(--primaryClr);
      }
    }
  }

  // @media screen and (min-width: 750px) {
  //   padding-top: 60px;
  // }

  // @media screen and (min-width: 1200px) {
  //   padding-top: 70px;
  // }

  scroll-snap-type: mandatory;

  .custom_placeholder_parent {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(87, 84, 85, 0.84);

    .custom_placeholder_child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100px;
    }
  }
}
.form-control {
  background: transparent !important;
  color: var(--whiteClr) !important;
  &:focus {
    border-color: var(--primaryClr);
  }
}
.section-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  h2 {
    margin-bottom: 0 !important;
  }
  a {
    background: var(--primaryClr);
    color: var(--blackClr);
    font-size: 15px;
    font-weight: 600;
    font-family: sans-serif;
    height: 40px;
    margin-left: 8px;
    padding: 10px 20px;
    border: 0;
    border-radius: 25px;
    transition: all 0.4s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
}

.betslip-content.inline_betslip {
  .stack-win-value {
    display: flex;
    margin-bottom: 5px;
    > div {
      width: calc(33.33% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }

    .odds,
    .stack_field_value {
      .form-control {
        border-color: var(--secondaryClr);
      }
    }
  }
  .winning {
    p {
      line-height: 1.3;
      margin-bottom: 0;
      @media only screen and (max-width: 767.98px) {
        font-size: 0.8rem;
      }
    }
  }
  .auto-stack {
    margin-bottom: 10px;
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      li {
        width: calc(25% - 0.5rem);
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        &:last-child {
          margin-right: 0;
        }
        .stack-btn {
          background: var(--secondaryClr);
          color: var(--whiteClr);
          width: 100%;
          padding: 5px;
        }
      }
    }
  }
  .btn_group {
    display: flex;
    > div {
      width: calc(50% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    .bet-now {
      .place-btn {
        background: #2ebd97;
        color: var(--whiteClr);
        font-size: 1.15rem;
        font-weight: 600;
        width: 100%;
        &:hover {
          background: #025f18;
        }
      }
    }
    .cancel-btn {
      background: var(--lightClr);
      color: var(--siteRedClr);
      font-size: 1.15rem;
      font-weight: 600;
      width: 100%;
      &:hover {
        background: var(--siteRedClr);
        color: var(--whiteClr);
      }
    }
  }
}

.betslip_popup {
  padding-left: 0 !important;
  .modal-body {
    background: var(--headClr);
    border: 1px solid #222;
    border-top-width: 1px;
    border-radius: 4px 4px 0 0;
    padding: 0;
    .table-responsive {
      padding: 0;
    }
    table {
      margin-bottom: 0;
      thead {
        background: #222;
        tr {
          th {
            border: 0;
          }
        }
      }
      tbody {
        tr {
          border-color: #333;
        }
        td {
          &.yes {
            color: #72bbef;
          }
          &.no {
            color: #faa8ba;
          }
        }
      }
    }
  }
  .modal-footer {
    background: #444;
    padding: 7px 10px;
    border: 0;
    .btn {
      min-width: 100px;
      margin: 0 auto;
    }
  }
  &.outer_popup {
    .modal-body {
      table {
        thead {
          tr {
            th {
              @media screen and (max-width: 767.98px) {
                &:nth-child(3) {
                  min-width: 200px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.inner_popup {
    .modal-body {
      table {
        thead {
          tr {
            th {
              @media screen and (max-width: 767.98px) {
                &:not(:first-child) {
                  min-width: 80px;
                }
                :last-child {
                  min-width: 200px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.contact-social ul {
  display: flex;
  li img {
    width: 60px;
    height: auto;
    margin: 0 15px;
  }
  a {
    color: #fff;
  }
}
.privay-content {
  margin-top: 20px;
  p.privacy-points {
    margin-bottom: 9px;
    width: 20%;
    position: relative;
    border-bottom: 1px solid #fff;
    padding-left: 12px;
    &::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 50%;
      top: 6px;
      left: 0;
    }
  }
}
.responsible-game {
  margin-top: 20px;
}
.exchange-popup {
  z-index: 99999999999;
  .modal-content {
    background: #1a1e29;
    border-radius: 0 !important;
    .modal-header,
    .modal-body,
    .modal-footer {
      border: 0;
      padding: 15px;
    }
    .btn-close {
      background-image: url(assets/images/close.svg);
      opacity: 1;
    }
    .balnace-wallet {
      display: flex;
      flex-direction: column;
      .balance-value {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        svg {
          width: 25px;
          height: 25px;
          margin-right: 5px;
          color: var(--primaryClr);
        }
      }
    }
    .amount-input {
      form {
        margin-top: 5px;
        input {
          background: #fff !important;
          border-width: 2px;
          border-radius: 5px;
          color: #000 !important;
        }
        .exchange-button {
          display: flex;
          justify-content: space-between;
          button {
            font-size: 16px;
            padding: 6px 8px;
          }
        }
      }
    }
  }
}
input[type="date"].form-control::-webkit-calendar-picker-indicator {
  filter: invert(1);
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.header.beforeheader {
  button.navbar-toggler {
    display: none;
  }
}
.page-details {
  .game-points {
    background: var(--headClr);
    padding-bottom: 0.05rem;
    border-radius: 0;
    .title {
      background: var(--whiteClr);
      border-bottom: 1px solid #7e97a7;
      span {
        color: var(--blackClr);
        font-size: 13px;
        font-weight: 600;
      }
    }
    ul {
      li {
        padding: 0 0 0 0.75rem;
        border-color: #333;
        &:last-child {
          border-bottom: 0;
        }
        .team-name {
          color: #bcbcbc;
        }
        .match_odds {
          .info-block {
            &:first-child {
              background: #d7e8f4 !important;
            }
            &:nth-child(2) {
              background: #b7d5eb !important;
            }
            &:nth-child(3) {
              background: #72bbef !important;
            }
            &:nth-child(4) {
              background: #faa8ba !important;
            }
            &:nth-child(5) {
              background: #efd3d9 !important;
            }
            &:last-child {
              background: #f6e5ea !important;
            }
            span {
              font-weight: 600;
            }
          }
        }
      }
    }
    &.bookmaker {
      background: #faf8d8;
      .game-top-area {
        .title {
          background: transparent;
          border: 0;
          span {
            color: #bdc4c7;
            padding: 0;
          }
        }
        .range {
          position: relative;
          left: auto;
          transform: unset;
          background: #bed5d8;
          padding: 5px 10px;
          margin: 4px 0;
          border-radius: 5px;
        }
      }
      ul {
        background: transparent;
        li {
          .match_odds {
            @media only screen and (max-width: 767.98px) {
              width: 140;
            }
            .back_all_odds {
              background: linear-gradient(
                90deg,
                rgba(130, 183, 221, 0.15) 0%,
                rgba(130, 183, 221, 0.8) 65%
              );
              display: flex;
              padding: 0.25rem;
              @media only screen and (max-width: 767.98px) {
                background-image: linear-gradient(
                  90deg,
                  rgba(151, 199, 234, 0.7) 0%,
                  #97c7ea 65%
                );
                width: 70px;
              }
              .info-block {
                &:nth-child(3) {
                  background: #72bbef !important;
                  border: 1px solid #fff !important;
                  border-radius: 4px;
                  span {
                    color: var(--blackClr);
                  }
                }
                @media only screen and (max-width: 767.98px) {
                  &:nth-child(1),
                  &:nth-child(2) {
                    display: none !important;
                  }
                  &:nth-child(3) {
                    display: block !important;
                    width: 100%;
                  }
                }
              }
              .bookmaker_susp {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.4);
                color: var(--whiteClr);
                font-weight: bold;
                text-shadow: 0 0.2666666667vw 1.0666666667vw rgb(0 0 0 / 50%);
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                  opacity: 0.8;
                }
              }
            }
            .lay_all_odds {
              background: linear-gradient(
                270deg,
                rgba(231, 170, 184, 0.15) 5%,
                rgba(231, 170, 184, 0.8) 60%
              );
              display: flex;
              padding: 0.25rem;
              @media only screen and (max-width: 767.98px) {
                background-image: linear-gradient(
                  270deg,
                  rgba(247, 205, 214, 0.75) 5%,
                  #f0c0cb 60%
                );
                width: 70px;
              }
              .info-block {
                &:nth-child(1) {
                  background: #faa9ba !important;
                  border: 1px solid #fff !important;
                  border-radius: 4px;
                  span {
                    color: var(--blackClr);
                  }
                }
                @media only screen and (max-width: 767.98px) {
                  &:nth-child(1) {
                    display: block !important;
                    width: 100%;
                  }
                  &:nth-child(2),
                  &:nth-child(3) {
                    display: none !important;
                  }
                }
              }
            }
            .info-block {
              background: transparent !important;
              margin: 0 !important;
              line-height: 27px;
              span {
                color: #59708d;
              }
              @media only screen and (max-width: 767.98px) {
                &:nth-child(1) {
                  display: block !important;
                }
                &:nth-child(2),
                &:nth-child(3) {
                  display: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .title {
    &.market_heading {
      background: #222;
      span {
        font-weight: bold;
      }
    }
  }
}

@media only screen and (max-width: 1199.98px) {
  .header_open {
    .navbar {
      transform: translateX(0px) !important;
      .navbar-toggler {
        right: -12px !important;
      }
    }
  }
  .header {
    .navbar {
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(-230px);
      width: 230px;
      height: 100%;
      background: var(--bodyClr);
      padding: 1rem;
      z-index: 9;
      transition: all 0.3s;
      .navbar-toggler {
        position: absolute;
        top: 70px;
        right: -30px;
        background: var(--whiteClr);
        padding: 0.15rem 0.25rem;
        border-radius: 25px;
        z-index: 99;
        .navbar-toggler-icon {
          width: 20px;
          height: 22px;
        }
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
      .navbar-collapse {
        position: fixed;
        top: 1rem;
        left: 1rem;
        width: 200px;
        height: 100%;
        display: block !important;
        background: transparent;
        padding: 0;
        margin: 0;
        .navbar-nav {
          width: 100%;
          flex-direction: column;
          a {
            color: var(--whiteClr);
            font-size: 1em;
            text-transform: capitalize;
            width: 100%;
            height: 30px;
            line-height: 1.33;
            margin-bottom: 1.1rem;
            padding: 0;
            &:last-child {
              margin-bottom: 0;
            }
            svg,
            img {
              fill: var(--whiteClr);
              width: 20px;
              height: 20px;
              margin-right: 7px;
            }
            &:hover {
              opacity: 0.8;
            }
            &.logoImg {
              width: fit-content;
              margin-bottom: 2.25rem;
              img {
                width: 150px;
                height: auto;
                @media screen and (max-width: 767.98px) {
                  width: 120px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  // .depositSection .heading_img h1,
  // h2,
  // h3 {
  //   font-size: 17px !important;
  //   text-overflow: ellipsis;
  //   overflow: hidden;
  //   white-space: nowrap;
  //   width: 84%;
  //   height: 75px !important;
  //   display: block !important;
  //   top: 27px;
  //   letter-spacing: inherit !important;
  // }
  .cancel_bonus_modal {
    .modal-content {
      width: 100% !important;
      // margin: 0 auto;
    }
  }
  .depositIconNew {
    display: block;
    margin-inline: 6px;
    font-size: 20px;
    cursor: pointer;
    img {
      width: 22px;
    }
  }
  .main .providers_sec .providers_logo {
    flex-wrap: wrap !important;
  }
  #chat-widget-container {
    bottom: 60px !important;
  }
  .sports_iconsss {
    ul {
      gap: 25px;
      li {
        width: 42% !important;
      }
    }
  }
  .sportsss .sports_ev {
    padding: 0 15px !important;
  }
  .bottom_img {
    display: none !important;
  }
  .bottom_cont {
    width: 100% !important;
  }
  .main .top-Games {
    text-align: center;

    h2 {
      font-size: 22px !important;
      font-weight: 700 !important;
      position: relative !important;
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
    h3 {
      font-size: 27px;
      // font-family: "brush Script MT";
      color: #999191;
      line-height: 1;
    }
    p {
      color: #67718f;
      font-size: 14px;
      margin-top: -5px;
      font-weight: 600;
      line-height: 1.3;
    }
  }
  .progresss p {
    font-size: 12px !important;
  }
  .image_box {
    position: relative;
    width: 100% !important;
    text-align: center;
    margin: 0 auto;
    img {
      width: 330px !important;
      margin: 0 auto 20px;
    }
  }
  .sportsss {
    width: 100% !important;
  }
  .sports_ev {
    h3 {
      font-size: 25px !important;
      white-space: nowrap;
      margin-top: 15px !important;
      font-weight: 800;
    }
    p {
      margin-inline: 10px !important;
      margin-top: 15px !important;
    }
  }
  .platform-item {
    display: grid !important;
  }

  .tabs_neww .nav-tabs {
    flex-wrap: nowrap !important;
    overflow-x: scroll !important;
    justify-content: flex-start !important;
    li {
      button {
        white-space: nowrap;
        padding: 6px 33px 6px 8px !important;
        width: 155px !important;
        font-size: 14px !important;
        box-shadow: inherit !important;
      }
    }
  }
  .notice {
    width: 12% !important;
  }
  .contact_icons {
    width: 10% !important;
  }
  .stats-card {
    display: grid !important;
    ul {
      border-radius: 0 !important;
      width: 100% !important;
    }
  }
  .cards {
    width: 100% !important;
  }
  .agent-head {
    justify-content: space-between;
    .social-icon {
      width: 20%;
      margin: 2%;
    }
  }
  .header {
    .navbar {
      .navbar-toggler {
        border-radius: 0.375rem;
        line-height: 16px;
        padding: 0 0.15rem;
        right: -37px;
        top: 16px;
      }
    }
    &.aftrlgn {
      .logo {
        margin-left: 5px;
        margin-right: 0;
      }
    }
    .headerRight {
      .menu-btn {
        margin-left: 0;
      }
      .funds {
        ul {
          margin-right: 0px;
          padding: 0px !important;
          li {
            margin: 0 4px !important;
            a {
              color: var(--whiteClr);
              display: block;
              border-radius: 22px;
              padding: 3px 10px !important;
              span {
                padding-left: 2px;
              }
            }
          }
          li:after {
            display: none;
          }
        }
      }
      ul {
        li {
          margin: 0 2px;
        }
      }
    }
  }
  .custom-tabs {
    padding-top: 0px;
    .sports-name-listing {
      ul {
        padding: 5px;
        margin-bottom: 5px;
      }
    }
  }
  .page-details {
    .game-top-area {
      .range {
        display: none;
      }
      .date {
        display: none;
      }
    }
    .game-points {
      ul {
        li {
          display: flex;
          padding-left: 0.5rem;
          border-bottom: 1px solid #2f3940;
          .team-name {
            width: calc(100% - 150px);
            flex-direction: revert;
            justify-content: space-between;
            color: var(--whiteClr);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 0.25rem 0 0;
            .time {
              display: none;
            }
          }
          .status-btns {
            width: 140px;
            display: flex;
            align-items: center;
            .info-block {
              width: inherit;
              display: none;
              &:nth-child(3),
              &:nth-child(4) {
                display: flex;
                width: 50%;
              }
              &:nth-child(4) {
                border: 0;
              }
              &:first-child {
                margin-left: 0;
                display: none;
              }
            }
            .info-block.no:nth-child(2) {
              display: flex;
              width: 50%;
            }
          }
        }
      }
    }
  }
  .sports_page,
  .custom-tabs {
    .tab-pane {
      .game-listing {
        .match-info {
          display: block;
          .match-name {
            width: 100%;
          }
          .live-match {
            height: 10px;
            width: 10px;
            margin-right: 8px;
            background: #c4cfd6;
            border: 1px solid #366601;
            border-radius: 50%;
          }
          .listing {
            padding-bottom: 5px;
            li {
              width: 100%;
              margin-right: 3px;
              &:nth-child(2),
              &:nth-child(4) {
                margin-right: 6px;
              }
            }
          }
        }
        .match-listing-head {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .header {
    .headerRight {
      .funds {
        ul {
          li {
            a {
              display: flex;
              border-radius: 22px;
              font-size: 12px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
.mybets-tabs-section {
  ul {
    width: max-content;
    display: flex;
    border: 1px solid #545454;
    border-top: 1px solid #545454 !important;
    margin-bottom: 20px !important;
    @media only screen and (max-width: 767px) {
      overflow-x: scroll;
      width: auto;
      margin: 15px 0px !important;
    }
    li {
      padding: 8px 15px !important;
      border-bottom: 0 !important;
      border-right: 1px solid #545454;
      white-space: nowrap;
      &:hover {
        background-color: var(--primaryClr);
      }
      &.active {
        background-color: var(--primaryClr);
      }
      div {
        color: #fff;
      }
    }
  }
}

.account-statement {
  table {
    tbody {
      tr {
        td {
          span.lay {
            background: #faa8ba;
            border-radius: 5px;
            font-size: 11px;
            color: #000;
            padding: 5px;
            width: 40px;
            justify-content: center;
            display: flex;
          }
        }
        td {
          span.back {
            background-color: #72bbef;
            border-radius: 5px;
            font-size: 11px;
            color: #000;
            padding: 5px;
            width: 40px;
            justify-content: center;
            display: flex;
          }
        }
      }
    }
  }
}
.transfer_info_page {
  input {
    &::placeholder {
      color: #fff;
      font-weight: bold;
    }
  }
  button[type="submit"] {
    margin-top: 15px;
  }
  .upi-list {
    li {
      border: 0 !important;
      margin-top: 20px;
    }
  }
}
.modal-confirm {
  color: #000;
}

.withdrawlist {
  table {
    tr th {
      white-space: nowrap !important;
    }
  }
}

.withdraw-style {
  width: 100% !important;
}
.CasinoSecton {
  .top_nav {
    overflow-x: scroll;
    padding: 9px 14px;
    gap: 8px 8px;
    border: 0 !important;
    flex-wrap: nowrap;
    white-space: nowrap;
    &::-webkit-scrollbar {
      width: 6px;
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      background: #6e6f7e;
    }
    &::-webkit-scrollbar-thumb {
      background: #f3486a;
      &:hover {
        background: #f3486a;
      }
    }
    .nav-item {
      padding: 3px 10px 1px;
      display: grid;
      gap: 0px 5px;
      background: #202a39;
      width: 150px;
      height: 70px;
      border-radius: 4px;
      a.nav-link {
        color: #88b6ff;
        font-weight: 600;
        line-height: 22px;
        font-size: 14px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:hover {
          background-color: transparent;
          color: #fff !important;
        }
        &.active {
          background-color: transparent;
          color: #fff !important;
        }
        img {
          width: 20px;
          margin-bottom: 5px;
        }
      }
    }
  }
  ul {
    overflow-x: scroll;
    padding: 9px 14px;
    gap: 8px 8px;
    border: 0 !important;
    flex-wrap: nowrap;
    white-space: nowrap;
    &::-webkit-scrollbar {
      width: 6px;
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      background: #6e6f7e;
    }
    &::-webkit-scrollbar-thumb {
      background: #f3486a;
      &:hover {
        background: #f3486a;
      }
    }
    li {
      padding: 3px 10px 1px;
      display: grid;
      gap: 0px 5px;
      background: #202a39;
      width: 150px;
      height: 70px;
      border-radius: 4px;
      button {
        color: #88b6ff;
        font-weight: 600;
        line-height: 22px;
        font-size: 14px;
        &.active {
          background-color: transparent !important;
          color: #fff !important;
          border-radius: 0 !important;
          border: 0 !important;
        }
        &:hover {
          background-color: transparent !important;
          color: #fff !important;
          border-radius: 0 !important;
          border: 0 !important;
        }
        span {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 20px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .tab-content {
    width: 85%;
    margin: 0 auto;

    .games-section {
      margin-top: 24px;
      .game-heading {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        img {
          width: 30px;
          margin-right: 10px;
        }
        span {
          color: #09ff8d;
          font-size: 24px;
          font-weight: 600;
        }
      }
      ul {
        overflow: hidden;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        li {
          background-color: transparent;
          width: 23%;
          margin: 0.5%;
          display: block;
          height: auto;
          position: relative;
          overflow: hidden;
          padding: 0;
          img {
            width: 100% !important;
            border-radius: 8px;
          }
          .casino_overlay {
            position: absolute;
            inset: 0 0 0 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(
              0deg,
              rgba(37, 35, 152, 0.49),
              rgba(37, 35, 152, 0.49)
            );
            border-radius: 10px;
            content: "";
            transition: all 0.9s;
            img {
              width: 0px !important;
              height: 0px;
              border-radius: 10px;
            }
          }
          &:hover .casino_overlay {
            inset: 0 0 0 0%;
          }
          &:hover .casino_overlay img {
            width: 80px !important;
            height: 80px !important;
          }
        }
      }
    }
  }
}

.modal.login-modal {
  overflow: hidden;
  display: flex !important;
  z-index: 9999;
  .modal-content {
    width: 100%;
    background-color: var(--bodyClr);
    padding: 0px 20px;
    border-radius: 20px;
    max-height: calc(100vh - 22px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #222;
    }
    &::-webkit-scrollbar-thumb {
      background: #6e6f7e;
      &:hover {
        background: #444;
      }
    }
    .modal-header {
      border: 0;
    }
    .modal-body {
      .login-section {
        display: flex;
        .login-leftPart {
          width: 50%;
          text-align: center;
          img {
            max-width: 380px;
            &.signin {
              max-width: 480px;
            }
          }
        }
        .Login-rightPart {
          width: 100%;
          text-align: center;
          background: #2b2b2b;
          border-radius: 20px;
          padding: 25px 30px 30px;
          .modal-title {
            border-bottom: 1px solid rgba(138, 146, 171, 0.4);
            padding-bottom: 15px;
          }
          .form-steps {
            padding-top: 25px;
            input {
              background: #181818 !important;
              padding: 13px 50px 15px 18px;
            }
            button {
              width: 45%;
              height: 45px;
              font-size: 13px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}

.faq-section {
  margin: 20px 0;
  display: flex;
  background-color: var(--headClr);
  padding: 15px;
  justify-content: space-between;
  .faq-image {
    position: relative;
    right: 24px;
    img {
      width: 500px;
    }
    .FaqAnimation {
      position: absolute;
      top: 100px;
      left: -61px;
      transition: transform 1s ease-in;
      animation: up-and-down 1.25s linear infinite alternate;
      img {
        width: 250px;
      }
    }
    @keyframes up-and-down {
      0% {
        transform: translateY(0%);
      }

      100% {
        transform: translateY(-20%);
      }
    }
  }
  .accordion {
    width: 100% !important;
  }
  .accordion-item {
    border: 0;
    background-color: #1c1d20 !important;
    margin-bottom: 10px;
    color: #fff;
    .accordion-header {
      button {
        background-color: #212326 !important;
        font-weight: 500;
        color: #fff;
        box-shadow: none;
        &[aria-expanded="true"] {
          color: var(--primaryClr);
        }
        &:after {
          filter: invert(186%) sepia(7%) saturate(985%) hue-rotate(196deg)
            brightness(99%) contrast(90%);
        }
        &:not(.collapsed)::after {
          background-image: url(./assets/images/arrow.png);
          rotate: 180deg;
          filter: inherit;
        }
      }
    }
  }
}

@-webkit-keyframes sports-book-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50%,
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes sports-book-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50%,
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-webkit-keyframes icon-animation {
  to {
    -webkit-transform: scaleX(0.6);
    transform: scaleX(0.6);
  }
}
@keyframes icon-animation {
  to {
    -webkit-transform: scaleX(0.6);
    transform: scaleX(0.6);
  }
}

@-webkit-keyframes icon-animation1 {
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@keyframes icon-animation1 {
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@-webkit-keyframes sports-book-bouncing {
  0% {
    -webkit-transform: translateY(-60%);
    transform: translateY(-60%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  20% {
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  32.5% {
    -webkit-transform: translateY(-15%);
    transform: translateY(-15%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  41% {
    -webkit-transform: translateY(-8%);
    transform: translateY(-8%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  46.5% {
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  12.5%,
  27.5%,
  37.5%,
  43.5% {
    -webkit-transform: translateY(0) scale(1.1, 0.9);
    transform: translateY(0) scale(1.1, 0.9);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes sports-book-bouncing {
  0% {
    -webkit-transform: translateY(-60%);
    transform: translateY(-60%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  20% {
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  32.5% {
    -webkit-transform: translateY(-15%);
    transform: translateY(-15%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  41% {
    -webkit-transform: translateY(-8%);
    transform: translateY(-8%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  46.5% {
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  12.5%,
  27.5%,
  37.5%,
  43.5% {
    -webkit-transform: translateY(0) scale(1.1, 0.9);
    transform: translateY(0) scale(1.1, 0.9);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.kyc-form {
  form input {
    width: 100%;
    background: #20283f !important;
    border-radius: 10px;
    padding: 15px 10px 15px;
    outline: none;
    border: none;
    color: #858b9d !important;
    font-size: 12px;
    &::placeholder {
      color: #858b9d !important;
    }
  }
}
.promotion-section {
  padding: 40px 30px !important;
  .promo-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    form {
      display: flex;
      align-items: center;
    }
  }
}
.promo-section {
  margin-top: 20px;
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    li {
      background-color: #2b2b2b;
      margin-bottom: 15px;
      border-radius: 10px;
      width: 32.33%;
      padding: 10px;
      .promo-content {
        img {
          width: 100%;
          border-radius: 8px;
        }
        .content {
          padding: 25px 0px 15px 0px;
          h4 {
            font-size: 15px;
            margin-bottom: 6px;
            color: var(--whiteClr);
            font-weight: bold;
          }
          p {
            line-height: 150%;
            font-weight: 400;
            font-size: 14px;
            color: var(--whiteClr);
            margin-bottom: 30px;
          }
          a {
            border-radius: 10px;
            background: var(--primaryClr);
            padding: 8px 8px;
            color: var(--whiteClr);
          }
          small {
            font-size: 12px;
            color: #ff6600;
            display: block;
            margin-bottom: 10px;
          }
          button {
            border-radius: 10px;
            text-align: center;
            margin: 0 auto;
            display: block;
          }
        }

        .accordion {
          .accordion-item {
            background-color: transparent;
            border: 0;
          }
          .accordion-header {
            button {
              background-color: var(--primaryClr);
              color: var(--whiteClr);
              border-radius: 10px;
              width: 30%;
              height: 38px;
              padding: 0;
              margin: 0 auto;
              font-size: 14px;
              box-shadow: 2px 3px 8px 2px #201e1e;
            }
          }
          .accordion-collapse,
          .modal-body {
            color: #fff;
            border-radius: 8px;
            background: #191919;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
.promotion-modal {
  .modal-dialog {
    max-width: 500px !important;
    border-radius: 0;
    .modal-content {
      border-radius: 8px;
      background: #211f1f;
      .modal-header {
        border-radius: 0;
        background: #211f1f;
        border: 0;
        button {
          filter: invert(1);
        }
      }
      .modal-body {
        color: #fff;
        border-radius: 0px;
        background: #211f1f;
        margin-top: 10px;
      }
    }
  }
}
.main .PageSection .account-statement .mybets-tabs-section {
  ul {
    margin-bottom: 15px !important;
  }
}
.withdrawlist {
  table {
    thead tr {
      background: #191919;
      box-shadow: 0px 1px 0px #65739c;
      th {
        padding: 20px;
      }
    }
    tbody {
      background-color: #2b2b2b;

      tr td {
        border-bottom: 1px solid rgba(138, 146, 171, 0.3);
        padding: 20px 20px;
      }
    }
  }
}
.bonus_table {
  overflow-y: auto;
  height: 762px;
  table {
    color: #fff;
    th {
      white-space: nowrap;
    }
  }
}
.primary_card {
  border-radius: 10px;
  border: 1px solid #d58b2f;
  border-image-source: linear-gradient(to left, #499b4c, #d84c48);
  border-image-slice: 1;
  .stats-card {
    display: flex;
    ul {
      border-right: 1px solid #353c4b;
      width: 50%;
      &:last-child {
        border: 0;
      }
      li {
        // height: 50px;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #353c4b;
        border-image-source: linear-gradient(to left, #499b4c, #d84c48);
        border-image-slice: 1;
        font-weight: 700;
        span {
          width: 50%;
          // font-size: 13px;
          display: block;
          font-weight: normal;
        }
      }
    }
  }
}
.progressbar-section {
  background: #393939;

  .progress-bar {
    min-width: 5% !important;
    background-color: var(--primaryClr);
  }
}
.main_card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.cards {
  background: url(./assets/images/banner/bonus.png);
  border-radius: 10px;
  box-shadow: 0 1px 10px 3px rgba(58, 58, 58, 0.439);
  margin: 0.5%;
  cursor: pointer;
  padding: 15px;
  width: 32.33%;
  span {
    color: var(--whiteClr);
    margin-left: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
  }
}
.amount_data {
  justify-content: space-between;
}
.amount_data {
  .status {
    color: green;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
  }
  .status_claimed {
    color: green;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
  }
  .staus_pending {
    color: #f1c232;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
  }
  .staus_expired {
    color: #cc0000;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
  }
  .staus_canceled {
    color: #cc0000;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
  }
}
.cancel_bonus {
  background: red;
  padding: 1px 2px;
  font-size: smaller;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
}
.bonus_cards {
  color: var(--whiteClr);
  margin-top: 10px;
  background: #2b2b2b;
  padding: 20px 30px 20px;
  margin-bottom: 24px;
}
.referr_table {
  color: #fff;
  thead {
    background: #44516d;
  }
  tbody {
    tr {
      background: #727d95;
    }
  }
}
.back-link {
  font-size: 10px;
  color: var(--whiteClr);
  text-transform: uppercase;
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
  }
}
.referral_card_main {
  display: grid !important;
  margin-top: 10px !important;
  justify-content: normal !important;
  .referrals_cards {
    width: 100%;
    margin-top: 10px;
  }
}

.referrals_cards {
  background: #2b2b2b;
  border-radius: 5px;
  // padding: 20px;
  flex: 1;

  .referral_card_icon {
    color: var(--primaryClr);
  }

  h5 {
    font-size: 1rem;
  }
}
.referr_user {
  display: flex;
  gap: 10px;
  // margin-top: 15px;
  p {
    font-size: x-large;
    font-weight: 500;
    margin-bottom: 0;
  }
  svg {
    font-size: large;
    margin-top: 8px;
  }
}
.referral_card_main {
  display: flex !important;
  gap: 15px;
  width: 100%;
  justify-content: space-between;
}
.referr_tabs {
  ul {
    gap: 20px;
    border-bottom: 0;
    li {
      button {
        background: #44516d !important;
        color: #fff;
        border: 0;
        border-radius: 5px;
        padding: 8px 35px;
        &.active {
          background: #e20001 !important;
          color: #fff !important;
          border: none;
          padding: 8px 35px;
        }

        &:hover {
          background: none;
          border: none;
          color: #fff;
        }
      }
    }
  }
}
.referr_table {
  color: #fff;
  thead {
    background: #191919;
  }
  tbody {
    tr {
      background: #2b2b2b;
    }
  }
  .dropdown-item {
    display: flex;
    justify-content: space-between;
    color: #fff !important;
    &:hover {
      background: none;
    }
  }
  .dropdown-menu {
    margin-top: 10px;
    background: #727d95;
  }
  .dropdown {
    button {
      background: #727d95;
      border: 0;
      &:hover {
        background: none;
      }
    }
  }
}
.referral_link {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #2b2b2b;
  padding: 6px 20px;
  gap: 10px;
  h5 {
    margin-right: 20px;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
  }

  div {
    background: #201e1e;
    color: #e7e4e4;
    padding: 8px 15px;
    border-radius: 5px;
    flex: 1;
  }
}
.contact_icons {
  width: 3%;
  margin-right: 5px;
}
.anouncement {
  marquee {
    font-weight: 700;
    color: var(--whiteClr);
    height: 100%;
    display: flex;
    font-size: 17px;
    justify-content: center;
    align-items: center;
  }
  width: 100%;
  position: relative;
  height: 55px;
  margin: 0 auto;
  padding: 5px;
  border-radius: 10px;
  background: linear-gradient(#191919 -1%, #201e1f 52%, #191919);
  .notice {
    position: absolute;
    width: 5%;
    height: 100%;
    top: 0;
    border-radius: 10px 0 0 10px;
    display: flex;
    z-index: 99;
    background: linear-gradient(#393939 -1%, #000000 52%, #393939);
    justify-content: center;
    align-items: center;
    img {
      width: 48px;
    }
  }
}
.top-Games {
  text-align: center;
  padding: 30px;
  &.popularGmaes {
    background: #fff;
  }
  h2 {
    font-size: 30px;
    letter-spacing: 1.5px;
    // font-family: "Abril Fatface", cursive;
    position: relative;
    margin-bottom: 8px;
    color: var(--primaryClr);
    &::before {
      content: "";
      position: absolute;
      background-image: url("./assets/images/titleset.png");
      background-position-x: 282px;
      background-repeat: no-repeat;
      background-size: contain;
      left: 0;
      width: 450px;
      height: 25px;
      top: 9px;
    }
    &::after {
      content: "";
      position: absolute;
      background-image: url("./assets/images/titleset.png");
      background-position-x: -298px;
      background-repeat: no-repeat;
      width: 450px;
      height: 25px;
      right: 0px;
      background-size: 478px 25px;
      top: 9px;
    }
  }
  h3 {
    font-size: 32px;
    margin-bottom: 18px;
    // font-family: "brush Script MT";
    color: #999191;
    line-height: 1;
  }
  p {
    color: var(--bodyClr);
    font-size: 18px;
    display: none;

    font-weight: 600;
    line-height: 1.3;
  }
}
.marqu {
  height: 25px;
  background: #201e1e;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;
  img {
    width: 35px;
  }
  marquee {
    width: 100%;
  }
  span {
    position: absolute;
    width: 35px;
    height: 25px;
    top: 0;
    right: 0;
    background: #201e1e;
    display: flex;

    color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 15px;
  }
}
.tabs_neww {
  background-color: #edeaea;
  .nav-tabs {
    border: 0;
    margin-bottom: 0;
    justify-content: center;
    background-color: var(--whiteClr);

    height: 62px;
    gap: 25px;
    li {
      button {
        height: 100%;
        color: var(--blackClr);
        font-size: 15px;
        font-weight: 600;
        padding: 0 !important;
        border: 0;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          background-color: var(--primaryClr);
          bottom: 0;
          width: 100%;
          height: 2px;
          bottom: 0;
          opacity: 0;
        }
        span {
          img {
            width: 18px;
            margin-right: 3px;
            filter: grayscale(100%);
          }
        }
        &.active,
        &:hover {
          color: var(--primaryClr);
          border: 0;
          img {
            filter: inherit !important;
          }
          &::before {
            opacity: 1 !important;
          }
        }
      }
    }
  }
}
.image_box {
  position: relative;
  width: 50%;
  text-align: center;
  img {
    width: 400px;
  }
}
.platform-item {
  padding: 50px 0;
  display: flex;
}

.sportsss {
  background: #ffffff;
  box-shadow: -1px 0px 12px 1px rgb(0 0 0 / 22%);
  width: 50%;
  border-radius: 20px;
  .sports_ev {
    padding: 15px;
    h3 {
      text-align: left;
      font-weight: 600;
      font-size: 30px;
      color: var(--primaryClr);
      margin-bottom: 35px;
      white-space: nowrap;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 105px;
        background: var(--primaryClr);
        height: 2px;
        bottom: -4px;
      }
    }
    p {
      text-align: initial;
      font-size: 16px;
      margin-top: 10px !important;
      font-weight: 500;
      color: var(--bodyClr);
      margin-bottom: 35px;
    }
  }
}
.progresss {
  width: 24%;
  text-align: center;

  img {
    width: 50%;
  }
  p {
    margin-top: 15px;
    letter-spacing: 1.5px;
    // font-family: "Abril Fatface", cursive;
    font-size: 20px;
    color: #f3486a;
  }
}
.AppdownloadSection {
  background-color: var(--whiteClr);
  .depositheading {
    margin-bottom: 0;
    justify-content: center;
    background-color: var(--whiteClr);
    height: 62px;
    color: var(--primaryClr);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    h2 {
      margin: 0;
      font-size: 22px;
      font-weight: 700;
    }
  }
}
.welcome-offer {
  background: url("./assets/images/welcome-offer-bg_.png");
  background-size: cover;
  background-position: top center;
  width: 100%;
  min-height: 420px !important;
  position: relative;
  margin-top: 0;
  .WelcomeContentSection {
    padding: 50px 0 0 28px;
    display: flex;
    align-items: start;
    height: 100%;
    flex-direction: column;
    &.afterloginContent {
      justify-content: center;
    }
    .welcomeContent {
      h4 {
        font-size: 30px;
        font-weight: 600;
        &.otherhead {
          font-size: 26px !important;
        }
        span {
          color: var(--primaryClr);
        }
      }
      h3 {
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 300 !important;
        line-height: 1.5;
        span {
          color: var(--primaryClr);
        }
      }
    }
    .WelcomeForm {
      margin-top: 38px;
      width: 55%;
      p {
        font-size: 18px;
        font-weight: 600;
      }
      form {
        display: flex;
        align-items: center;
        flex-direction: column;

        .position-relative {
          width: 100%;
        }
        input {
          border-radius: 8px;
          color: var(--whiteClr);
          width: 100% !important;
          &::placeholder {
            color: var(--whiteClr);
          }
        }
        img.inputFlag {
          position: absolute;
          width: 24px;
          left: 8px;
          top: 5px;
          margin: 0 !important;
          z-index: 9;
        }
        label.phonelable {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 50px;
          z-index: 9;
          color: var(--whiteClr);
          &::after {
            position: absolute;
            content: "";
            background-color: var(--whiteClr);
            height: 90%;
            width: 1px;
            left: -9px;
            top: 1px;
          }
        }
        input.phoneinput {
          padding-left: 95px !important;
          height: 38px;
          background: transparent;
          border: 1px solid var(--whiteClr);
          border-radius: 8px;
          width: 100%;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          &.mailinput {
            text-align: center;
            padding-left: 0 !important;
            color: var(--whiteClr);
          }
        }

        .panel {
          background-color: var(--primaryClr);
          color: var(--whiteClr);
          width: 100%;
          height: 38px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 17px;
        }
        .Issubmit {
          background-color: var(--blackClr);
          color: var(--whiteClr);
          width: 100%;
          height: 38px;
          border-radius: 5px;
          margin-left: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 17px;
        }
      }
    }
  }
  .WelcomeMobileImg {
    margin-top: -40px;
    display: flex;
    justify-content: end;
    img {
      width: 520px;
    }
  }
}
.depositSection {
  background-repeat: no-repeat;
  background-color: #edeaea;
  .depositheading {
    margin-bottom: 0;
    justify-content: center;
    background-color: var(--whiteClr);
    height: 62px;
    color: var(--primaryClr);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    h2 {
      margin: 0;
      font-size: 22px;
      font-weight: 700;
    }
  }
  .depositBottmScetion {
    padding: 50px 0;
  }
  .heading_img {
    position: relative;
    height: 50px;
    h5,
    h1,
    h2,
    h3 {
      color: var(--blackClr);
      position: absolute;
      margin: 0;
      font-size: 24px;
      font-weight: 600;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
.main_progress {
  ul {
    display: flex;
    padding: 30px;
    li {
      width: 32.33%;
      margin: 0.5%;
      text-align: center;
      // background-color: #2b2b2b;
      // border: 2px solid #131313;
      border-radius: 5px;
      overflow: hidden;
      img {
        width: auto;
        height: 200px;
      }
      a {
        padding: 15px;
        width: 100%;
        height: 100%;
        display: block;
        color: var(--primaryClr);
        position: relative;
        overflow: hidden;
        z-index: 2;
        &:hover span.circle,
        &.active span.circle {
          transform: scale(1);
          -webkit-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
        }
        p {
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 600;
          margin: 15px 0;
          z-index: 999999999;
          position: relative;
          color: #fff;
        }

        .circles-wrap {
          position: absolute;
          bottom: -70%;
          left: 35%;
          z-index: -1;
        }
        .circles {
          margin-bottom: -10px;
        }
        .circles {
          height: 330px;
          width: 330px;
          background: transparent;
          display: flex;
          position: relative;
          align-items: center;
          justify-content: center;
          z-index: 1;
        }
        span.circle.circle-1 {
          height: 200px;
          width: 200px;
          background: rgb(195 54 19 / 66%);

          display: inline-block;
          z-index: 5;
        }
        span.circle {
          border-radius: 50%;
          position: absolute;
          -webkit-transform: scale(0);
          -ms-transform: scale(0);
          transform: scale(0);
          -webkit-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
        }
        .circle {
          width: 150px;
          margin: 6px 6px 0px;
          display: inline-block;
          position: relative;
          text-align: center;
          line-height: 1.2;
        }
        span.circle.circle-2 {
          height: 230px;
          width: 230px;
          background: rgb(195 54 19 / 35%);
          z-index: 4;
        }
        span.circle.circle-3 {
          height: 270px;
          width: 270px;
          background: rgb(195 54 19 / 35%);
          z-index: 3;
        }
        span.circle.circle-4 {
          height: 310px;
          width: 310px;
          background: rgb(195 54 19 / 35%);
          z-index: 2;
        }
      }
    }
  }
}
.bottom_img {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: end;
  img {
    width: 540px;
    display: flex;
    justify-content: end;
  }
}
.bottom_cont {
  width: 50%;
  padding-left: 48px;
  h5 {
    color: var(--blackClr);
    letter-spacing: 1.5px;
    // font-family: "Abril Fatface", cursive;
    font-size: 24px;
    margin-bottom: 15px;
  }
  p {
    color: var(--blackClr);
    // font-family: cursive;
    font-size: 15px;
  }
}

.appsection {
  display: flex;
  .app-left-part,
  .app-right-part {
    width: 50%;
  }
  .app-left-part {
    h3 {
      font-family: inherit;
      text-align: left;
      margin-top: 40px;
      color: var(--primaryClr);
      margin-bottom: 10px;
    }
    p {
      text-align: left;
      font-weight: 100;
    }
    p.app-para {
      font-size: 18px;
      color: #505050;
      // font-family: cursive;
    }
  }
}
.sports_iconsss {
  ul {
    gap: 25px;
    li {
      width: 8%;
    }
  }
}
.new_iconssss {
  justify-content: space-around !important;
}

.pagecontent {
  padding: 20px 20px;
  background: #fff;
  width: 85%;
  margin: 20px auto;
  &.afterlogin {
    padding: 0;
  }
  .terms-content {
    ul {
      display: flex;
      align-items: center;
      li {
        width: 7%;
        margin: 1%;
      }
    }
  }
  .sectionTitle {
    color: var(--primaryClr);
    margin-bottom: 22px;
    font-size: 18px;
    font-weight: bold;
    &::after {
      background: #e20001;
    }
  }
  p {
    font-size: 18px;
    color: var(--bodyClr);
  }
  .payment-info {
    .other-contact {
      p.foloowingpoints {
        padding-left: 0;
        font-size: 18px;
      }
      p {
        position: relative;
        font-size: 16px;
        padding-left: 45px;
        &.social {
          img {
            position: static !important;
            width: 3%;
            min-width: 25px;
            margin-right: 5px;
            &.contact_icons {
              position: absolute !important;
            }
          }
        }
        span {
          text-decoration: underline;
          color: var(--primaryClr);
        }
        img {
          position: absolute;
          left: 0;
        }
      }
      .contact-social {
        height: 50px;
        display: flex;
        align-items: center;

        a {
          height: 30px;
          width: 30px;
          margin-right: 12px;
          display: flex;
          align-items: center;
        }
        img {
          position: relative;
          width: 100% !important;
          font-size: 15px;
        }
      }
    }
  }
  .sub-points p {
    position: relative;
    padding-left: 20px;
    font-size: 16px;
    span {
      color: var(--primaryClr);
      font-size: 18px;
      padding: 5px;
    }
    &::before {
      position: absolute;
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--primaryClr);
      left: 0;
      top: 11px;
    }
  }
  h3 {
    color: var(--primaryClr);
    font-size: 22px;
  }
}

.casino_sec .slick-initialized .slick-slide {
  width: 16.6% !important;
}
.casino_sec .slick-track {
  display: flex;
}

.fade.modal-backdrop.show {
  opacity: 0.9 !important;
}

.SignBonusModal .modal-dialog {
  max-width: 710px !important;
  button {
    filter: invert(1);
  }
}
.Sign-up-bonus {
  display: flex;
  align-items: center;
  .signup-img {
    width: 350px;
    margin-right: 25px;
  }
  h3 {
    margin-bottom: 25px;
    font-weight: 600;
  }
  p {
    font-size: 14px;
  }
  ul li {
    list-style-type: disc;
    margin-bottom: 12px;
    font-size: 14px;
  }
}

.quality-background {
  .top-Games {
    padding-bottom: 150px;
    h2 {
      color: var(--whiteClr);
      &:before,
      &::after {
        background-image: url(./assets/images/whiteline.png);
      }
    }
    p {
      color: #bfbfbf;
    }
  }
  .main_progress {
    margin-top: -140px !important;
    ul {
      padding-top: 0;
      li {
        // background-color: #131313;
        .quality-img {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #2b2b2d;
          img {
            height: 62px;
            filter: brightness(0) invert(1);
          }
        }
        p {
          color: var(--whiteClr);
        }
      }
    }
  }
}

.eye_ {
  fill: var(--primaryClr);
}

.sports-section {
  margin-top: -60px;
  padding: 0 25px;
  ul {
    display: flex;
    align-items: center;

    li {
      background-color: #1e1515;
      position: relative;
      width: calc(25% - 10px);
      margin-left: 20px;
      padding: 8px;
      border-radius: 16px;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
      cursor: pointer;
      overflow: hidden;
      transition: all 0.3s;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: -60%;
        top: 50%;
        width: 100px;
        height: calc(100% + 130px);
        background: linear-gradient(
          90deg,
          hsla(0, 0%, 100%, 0),
          hsla(0, 0%, 100%, 0.05) 25%,
          hsla(0, 0%, 100%, 0.13) 50%,
          hsla(0, 0%, 100%, 0.05) 75%,
          hsla(0, 0%, 100%, 0)
        );
        transform: rotate(30deg) translateY(-56%);
        border-radius: 30px;
        transition: all 0.4s;
      }
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.4);
      }
      &:hover::before {
        transform: rotate(30deg) translateY(-60%);
        left: 100%;
      }
      &:first-child {
        margin-left: 0;
      }
      a {
        .img-head {
          padding: 30px;
          background: #312323;
          border-radius: 8px;
          img {
            width: 60px;
          }
        }
        .right-content {
          padding-left: 30px;
          h2 {
            position: relative;
            color: #fff;
            padding-left: 0;
            text-align: left;
            font-size: 24px;
            font-weight: 700;
            line-height: 1.2;
          }
          p {
            color: var(--primaryClr);
            display: flex;
            align-items: center;
            margin-top: 8px;
            font-size: 14px;
            img {
              display: flex;
              align-items: center;
              margin-top: 8px;
              line-height: 1;
              width: 15px;
              transform: rotate(270deg);
            }
          }
        }
      }
    }
  }
}
.live_dealer_casino {
  background: #080808;
  padding: 30px 30px 10px;
  &.matchGameSection {
    display: flex;
    .right-sportsbanner {
      width: 35%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 355px;
      }
    }
    &.app-background {
      a {
        color: var(--primaryClr);
      }
      p {
        color: var(--primaryClr);
      }
    }
  }
  &.GameSlider {
    width: 100%;
    .gamesSection {
      width: 100%;

      .game_box {
        margin-right: 12px;
        img {
          border-radius: 5px;
        }
      }
    }
  }
  &.SpribeGamesSlider {
    .gamesSection {
      width: 100%;
      .spribegamesection {
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            width: 16.66%;
          }
        }
      }
    }
  }
  .Sec_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    h4 {
      color: var(--whiteClr);
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0;
      position: relative;
    }
    a {
      color: #ef1a1a;
      font-size: 13px;
      font-weight: 400;
      padding-top: 2px;
      padding-left: 20px;
    }
  }
  .gamesSection {
    // width: 65%;
    .casino_games {
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        gap: 14px 8px;
        div {
          width: calc(33.33% - 5.33px);
          @media (max-width: 767.98px) {
            width: calc(50% - 4px);
          }
          .game_box {
            width: 100% !important;
          }
        }
        &::-webkit-scrollbar {
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: transparent;
          border-radius: 10px;
        }
        &:hover {
          &::-webkit-scrollbar-thumb {
            background: rgb(76, 81, 89);
          }
        }
        li {
          width: calc(33.33% - 5.33px);
          display: inline-block;
          color: rgb(134, 151, 162);
          position: relative;
          padding: 0;
          transition: 0.3s ease-in-out;
          @media (max-width: 767.98px) {
            width: calc(50% - 4px);
          }
          a {
            width: 100%;
            display: inline-block;
            color: rgb(134, 151, 162);
            position: relative;
            padding: 0;
            margin-bottom: 0 !important;
            transition: 0.3s ease-in-out;
            img {
              border-radius: 5px;
            }
            &:hover {
              transform: translateY(-6px);
              box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.3);
            }
            span.like-btn {
              float: right;
              background-color: transparent;
              svg {
                fill: #aab8c2;
              }
            }
            &.active,
            &:hover {
              color: var(--whiteClr);
            }
            &:after {
              position: absolute;
              content: "";
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              width: 10px;
              height: 2px;
              background: var(--primaryClr);
              opacity: 0;
              transition: all 0.3s;
            }
            &.active {
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
      .allGames {
        display: flex;
        flex-wrap: wrap;
        gap: 14px 8px;
        &::-webkit-scrollbar {
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: transparent;
          border-radius: 10px;
        }
        &:hover {
          &::-webkit-scrollbar-thumb {
            background: rgb(76, 81, 89);
          }
        }
        .game_box {
          width: calc(12.5% - 7px);
          display: inline-block;
          color: rgb(134, 151, 162);
          position: relative;
          padding: 0;
          transition: 0.3s ease-in-out;
          @media (max-width: 767.98px) {
            width: calc(25% - 6px);
          }
          &:hover {
            transform: translateY(-6px);
            box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.3);
          }
          img {
            border-radius: 10px;
          }
          span.like-btn {
            float: right;
            background-color: transparent;
            svg {
              fill: #aab8c2;
            }
          }
          &.active,
          &:hover {
            color: var(--whiteClr);
          }
          &:after {
            position: absolute;
            content: "";
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 10px;
            height: 2px;
            background: var(--primaryClr);
            opacity: 0;
            transition: all 0.3s;
          }
          &.active {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .HomeMatchSection {
    width: 40%;
    display: flex;
    align-items: flex-end;
    .live-match-section {
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      color: #fff;
      min-height: 644px;
      padding: 0 8px;
      img {
        transform: rotate(180);
        transition: transform 1s ease-in;
        animation: up-and-down 1.25s linear infinite alternate;
      }
      @keyframes up-and-down {
        0% {
          transform: translateY(0%);
        }

        100% {
          transform: translateY(-20%);
        }
      }
      h4 {
        margin-top: 31px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        img {
          width: 40px;
          margin-right: 8px;
        }
      }
      .team-name-section {
        padding: 15px 8px 30px;

        .TeamSection {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
          .team-name {
            display: flex;
            .teamIcon {
              background: linear-gradient(
                rgb(252 249 249 / 10%) 0%,
                rgba(255, 255, 255, 0) 100%
              );
              width: 30px;
              height: 30px;
              margin-right: 8px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 16px;
              }
            }
            span {
              font-size: 18px;
              font-weight: 700;
            }
          }
          .team-odds {
            background: rgba(255, 255, 255, 0.05);
            padding: 2px 5px;
            min-width: 60px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            text-align: center;
            font-size: 15px;
          }
        }
      }
      .TeamOddsSection {
        .team-country-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          h4 {
            margin: 0;
            padding: 0 8px;
            font-size: 13px;
            color: rgba(255, 255, 255, 0.5);
            font-weight: 600;
            cursor: pointer;
            &:hover {
              color: #fff;
            }
            &:hover img {
              filter: brightness(100);
            }
            img {
              width: 18px;
            }
          }
          span {
            color: rgba(255, 255, 255, 0.5);
            font-weight: 600;
            font-size: 13px;
          }
        }
        .OddsBox {
          position: relative;
          margin-top: 12px;
          ul {
            display: flex;
            width: 100%;
            li {
              width: 48%;
              margin: 1%;
              a {
                background: #132534;
                width: 100%;
                display: flex;
                border-radius: 6%;
                flex-direction: column;
                align-items: center;
                min-height: 192px;
                padding: 15px;
                font-size: 12px;
                color: var(--whiteClr);
                &:hover {
                  background-color: rgba(19, 37, 52, 0.5);
                }
                .TeamNameImg {
                  margin: 0 auto;
                  .TeamImg {
                    background: linear-gradient(
                      rgba(252, 249, 249, 0.1) 0%,
                      rgba(255, 255, 255, 0) 100%
                    );
                    width: 50px;
                    height: 50px;
                    margin-right: 8px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                      width: 35px;
                    }
                  }
                  span {
                    max-height: 74px;
                    display: block;
                    height: 74px;
                    font-size: 12px;
                    font-weight: 600;
                    text-align: center;
                    margin-top: 12px;
                  }
                }
                .odds {
                  span {
                    font-size: 14px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
      .place-bet-section {
        margin-top: 16px;
        padding: 0 8px;
        form {
          display: flex;
          div {
            width: 49%;
            margin: 1%;
            input {
              width: 100%;
              background-color: #132534 !important;
              border: 1px solid transparent !important;
              border-radius: 4px;
              height: 40px;
              padding: 8px;
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
            }
          }

          button {
            background: rgba(255, 255, 255, 0.2);
            border: 0;
            height: 40px;
            width: 49%;
            border-radius: 4px;
            margin: 1%;
          }
        }
      }
      .slick-slider button.slick-arrow {
        background-color: #132534;
        border-radius: 50%;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        color: #fff;
        width: 32px;
        top: auto;
        bottom: -55px;
        padding: 0 8px;
        height: 32px;
        &.slick-prev {
          left: 14px;
          &::before {
            content: url("./assets/images/sports/left-arrow.png");
            opacity: 1;
            font-size: 25px;
          }
        }
        &.slick-next {
          right: 8px;
          &::before {
            content: url("./assets/images/sports/left-arrow.png");
            transform: rotate(180deg);
            opacity: 1;
            font-size: 25px;
          }
        }
      }
    }
  }
}
.sideBaractive {
  background: #e20001 !important;
  color: #fff !important;
}
.dropShow {
  opacity: 0 !important;
  cursor: pointer;
}
.invalid-feedback {
  margin-top: 0 !important;
}

.slots-section {
  margin: 25px auto 0;
  width: 95%;
  .game-heading {
    align-items: center;
    display: flex;
    margin-bottom: 24px;
    img {
      margin-right: 10px;
      width: 30px;
    }
    span {
      color: #fff;
      font-size: 24px;
      font-weight: 600;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 0;
    li {
      background-color: transparent;
      display: block;
      height: auto;
      margin: 0.5%;
      overflow: hidden;
      padding: 0;
      position: relative;
      width: 9%;
      &:hover .casino_overlay {
        inset: 0 0 0 0;
        img {
          height: 45px !important;
          width: 45px !important;
        }
      }
      img {
        border-radius: 8px;
        width: 100% !important;
      }
      .casino_overlay {
        align-items: center;
        background: linear-gradient(
          0deg,
          rgba(37, 35, 152, 0.49),
          rgba(37, 35, 152, 0.49)
        );
        border-radius: 10px;
        content: "";
        display: flex;
        inset: 0 0 0 100%;
        justify-content: center;
        position: absolute;
        transition: all 0.9s;
        img {
          border-radius: 10px;
          height: 0;
          width: 0 !important;
        }
      }
    }
  }
}
.inputfile {
  border-radius: 10px;
  padding: 0px 10px 0px;
  border: 1px solid var(--whiteClr);
  input {
    border: 0 !important;
  }
}
.cancel_bonus_modal {
  .modal-content {
    width: 50%;
    margin: 0 auto;
  }
}

.textarrowremoved input[type="number"]::-webkit-inner-spin-button,
.textarrowremoved input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.WithdrawList {
  .modal-dialog {
    z-index: 999999999;
    position: relative;
    .modal-title {
      color: #000;
    }
  }
}
h4 a,
h4 p {
  padding: 0 8px !important;
}
p.spribe {
  padding: 0 13px !important;
}
h2.ForgotPassword {
  margin-top: 45px;
}

.modal {
  &.changePasswordModal.show {
    .modal-dialog {
      transform: translate(0, 250px);
      border: 0;
      .modal-content {
        border-radius: 10px;
        .modal-header {
          background: #2b2b2b;
          border: 0;
          .modal-title {
            color: var(--whiteClr);
          }
          .btn-close {
            filter: invert(1);
          }
        }
        .modal-body {
          background: var(--bodyClr);
          .icon {
            position: absolute;
            top: 5px;
            right: 0.75rem;
            cursor: pointer;
            svg {
              fill: var(--whiteClr);
            }
            img {
              filter: invert(1);
            }
          }
        }
        .modal-footer {
          background: var(--bodyClr);
          border: 0;
          .closeBtn {
            min-width: 100px;
          }
          .saveBtn {
            background: var(--secondaryClr) !important;
            border-color: var(--secondaryClr) !important;
            opacity: 0.9;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.WithDrawSection {
  .heading {
    background-color: var(--headClr);
    padding: 5px;
    height: 40px;
    margin: 0;
    padding: 5px;
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
}

.WithdrawPageSection {
  .WithDrawSection.tabs-area {
    width: 760px;
    ul {
      border: 0;
      background: #2c2f3d;
      li {
        button {
          color: var(--whiteClr) !important;
          border-radius: 0 !important;
          text-align: center;
          font-weight: 400;
          border: 1px solid #444;
          background: rgb(44, 47, 61);
          background: linear-gradient(
            100deg,
            rgba(44, 47, 61, 1) 0%,
            rgba(57, 67, 91, 1) 100%
          );
          padding: 10px !important;
          &.active {
            background: rgb(6, 99, 177);
            background: linear-gradient(
              100deg,
              rgb(43 133 243) 0%,
              rgb(131 175 228) 100%
            ) !important;
          }
        }
      }
    }
    .tab-content {
      padding-bottom: 0 !important;

      .WithDrawBankDetails {
        background-color: #021622;
        background-color: #021622;
        background: url("./assets/images/banner/withdrawbackground.jpg") #021622;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 10px;

        .playerBankDetails {
          input,
          select {
            height: 45px;
            border-radius: 3px !important;
          }
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: var(--whiteClr);
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
          button.btn-primary {
            background: rgb(6, 99, 177);
            background: linear-gradient(
              100deg,
              rgba(6, 99, 177, 1) 0%,
              rgba(40, 131, 239, 1) 100%
            );
            border-radius: 5px !important;
            border: 0;
            font-weight: 400;
            height: 45px;
          }
        }
        h4 {
          font-size: 18px;
          margin-bottom: 15px;
        }
        .WithdrawTable {
          thead {
            border: 1px solid #7f898f;
            border-radius: 3px !important;
            th {
              white-space: nowrap;
            }
          }
          tbody {
            tr {
              td {
                color: var(--whiteClr) !important;
                padding: 12px 0 !important;
                line-height: 30px;
                input[type="checkbox"] {
                  display: none;
                }
                label {
                  display: block;
                  margin: 0.2em;
                  cursor: pointer;
                  padding: 0.2em;
                }
                button {
                  margin-right: 8px;
                }
              }
              th,
              td {
                color: var(--whiteClr) !important;
                padding: 12px !important;
                line-height: 30px;
                background: transparent !important;
                white-space: nowrap;
              }
            }
          }
        }
      }

      .WithdrawRequestSection {
        ul.nav-tabs {
          margin-top: 10px;
          background-color: var(--whiteClr) !important;
          button {
            background: var(--whiteClr) !important;
            color: var(--blackClr) !important;
            text-align: center !important;
            &.active {
              background: #169f00 !important;
              color: var(--whiteClr) !important;
            }
          }
        }
        .tab-content {
          background: url("./assets/images/banner/withdrawbackground.jpg")
            #021622;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          padding: 10px;
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: var(--whiteClr);
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
        }

        .WithdrawRequestBankSection {
          background-color: #021622;
          padding: 10px;
          .WithdrawTable {
            border: 0;
            thead {
              background-color: #2c2f3d;
              border: 0;
              border-radius: 3px !important;
              th {
                border: 0;
                color: var(--whiteClr);
                white-space: nowrap;
                background: transparent;
              }
            }
            tbody {
              tr {
                background: transparent;
                th {
                  background: transparent;
                  line-height: 40px;
                  color: var(--whiteClr);
                }
                td {
                  color: var(--whiteClr) !important;
                  padding: 12px 0.5rem !important;
                  line-height: 30px;
                  background: transparent;
                  button {
                    margin-right: 8px !important;
                  }
                }
              }
            }
          }
        }
        .sectionTitle {
          color: var(--whiteClr) !important;
          margin: 18px 0 25px !important;
          position: relative;
          text-align: center !important;
          &::after {
            position: absolute;
            content: "";
            border: 1px solid var(--whiteClr);
            left: 50%;
            transform: translate(-50%, -50%);
            width: 120px;
            bottom: 0;
          }
        }
        button.btn-primary {
          background: rgb(6, 99, 177);
          background: linear-gradient(
            100deg,
            rgba(6, 99, 177, 1) 0%,
            rgba(40, 131, 239, 1) 100%
          );
          border-radius: 5px !important;
          border: 0;
          font-weight: 400;
          height: 45px;
        }
      }
    }
  }
}

.updateBankDeatils {
  z-index: 999999;
  .modal-header {
    background-color: var(--headClr);
    color: var(--whiteClr);
    border: 0;
    button {
      filter: invert(1);
    }
  }
  .modal-body {
    background: #021621;
    input {
      background: transparent !important;
      color: var(--whiteClr) !important;
      border: 1px solid var(--whiteClr) !important;
      &::placeholder {
        color: var(--whiteClr);
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
        -webkit-text-fill-color: var(--blackClr) !important;
      }
    }
    button.btn-primary {
      background: rgb(6, 99, 177);
      background: linear-gradient(
        100deg,
        rgba(6, 99, 177, 1) 0%,
        rgba(40, 131, 239, 1) 100%
      );
      border-radius: 5px !important;
      border: 0;
      font-weight: 400;
      height: 35px;
      margin: 0 auto;
      width: 85px;
      text-align: center;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.WithdrawGif {
  img {
    animation: moving 3s infinite linear;
    margin-left: 20px;
    width: 365px;
    @keyframes moving {
      0% {
        left: 0;
      }
      50% {
        left: 0;
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
      }
      100% {
        left: 0;
      }
    }
  }
}

.WithdrawTable tbody tr td input[type="checkbox"] + label {
  display: block;
  margin-bottom: 0;
  cursor: pointer;
  padding: 0.2em;
}
.WithdrawTable tbody tr td input[type="checkbox"] {
  display: none;
}
.WithdrawTable tbody tr td input[type="checkbox"] + label:before {
  content: "\2714";
  border: 0.1em solid #fff;
  border-radius: 0.2em;
  display: inline-block;
  width: 2em;
  height: 2em;
  padding-left: 0.5em;
  padding-bottom: 0.3em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}
.WithdrawTable tbody tr td input[type="checkbox"]:checked + label:before {
  background-color: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #fff;
}
.WithdrawTable {
  thead {
    tr {
      th {
        white-space: nowrap;
      }
    }
  }
}
tbody tr {
  td,
  th {
    white-space: nowrap;
  }
}

.WithdrawPageSection
  .WithDrawSection.tabs-area
  .tab-content
  .WithdrawRequestSection
  .tab-content
  input:disabled {
  background: #415059 !important;
  border: 0 !important;
  cursor: no-drop !important;
}

.language-option {
  background-color: transparent;
  color: var(--whiteClr);
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  height: 36px;
  margin: 0 8px;
  border: 1px solid var(--whiteClr);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 575.98px) {
    padding: 6px 2px !important;
    margin: 0 2px;
  }
  .language-select {
    background-color: transparent;
    border: 0;
    color: var(--whiteClr);
    font-size: 16px;
    margin-right: 12px;
    padding: 8px 12px !important;
    border: 2px solid var(--whiteClr);
    border-radius: 5px;
    box-shadow: 0 1px 3px 5px rgba(0, 0, 0, 0.22);

    option {
      background-color: transparent;
      border: 0;
      color: var(--whiteClr);
    }
  }
  #google_translate_element {
    height: 17px !important;
    overflow: hidden !important;
    font-family: "GothamRoundedBold, sans-serif";
    font-size: 18px;
    position: relative;
    .goog-te-gadget {
      height: 24px;
      select {
        background: transparent;
        color: white;
        border: 0;
        padding: 0px;
        cursor: pointer;
        margin: 0 !important;
        &:first-child {
          display: block !important;
          background: transparent;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          text-align: center;
          max-width: 107px;
          margin: 0 auto !important;
          @media only screen and (max-width: 575.98px) {
            font-size: 11px;
            max-width: 100px;
          }
        }
        &:focus-visible {
          outline: 0;
        }
        option {
          color: #ffffff;
          background-color: var(--headClr);
          &:hover {
            background: var(--primaryClr);
          }
        }
      }
      a {
        display: none;
      }
    }
  }
}
.LoadMoreGames {
  background-color: #393939;
  border-radius: 5px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  &.SlotsBtn {
    background-color: rgb(57 57 57 / 24%);
  }
  a {
    color: var(--whiteClr);
  }
}
body > .skiptranslate {
  display: none;
}
.icon_2 {
  right: 1.75rem !important;
  top: 19px !important;
}

select.language-select {
  background-color: transparent;
  border: 0;
  color: var(--whiteClr);
  font-size: 16px;
  margin-right: 12px;
  padding: 8px 12px !important;
  text-transform: uppercase;
  option {
    background-color: transparent;
    color: var(--blackClr);
  }
}
// .depositSection .heading_img h1,
// h2,
// h3 {
//   color: var(--blackClr);
//   position: absolute;
//   margin: 0;
//   font-size: 24px;
//   font-weight: 600;
//   height: 100%;
//   display: flex;
//   align-items: center;
// }
.BlogSection {
  margin: 30px auto;
  ul {
    display: flex;
    align-items: self-start;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
    li {
      width: 31.8%;
      border-radius: 8px;
      background: rgb(23, 97, 23);
      background: linear-gradient(
        66deg,
        rgb(25 25 25) 0%,
        rgb(51 51 51) 58%,
        rgb(25 25 25) 100%
      );
      min-height: 335px;
      /* box-shadow: 0 3px 4px #323131; */
      border: 2px solid #232323;
      a {
        .BlogListing {
          display: flex;
          align-items: center;
          flex-direction: column;
          .BlogListImg {
            width: 100%;
            height: 160px;
            img {
              width: 100%;
              border-radius: 6px 6px 0 0;
            }
          }

          .BlogListText {
            line-height: inherit;
            h2 {
              color: var(--whiteClr);
              font-size: 20px;
              margin: 20px 8px 0;
            }
            p {
              color: #e2e2e2;
              margin: 0 8px 20px;
              font-size: 14px;
              padding-top: 30px;
            }
          }
        }
      }
    }
  }
}
.BlogContent {
  margin-top: 40px;
  .BlogText {
    margin-top: 20px;
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 20px;
    }
    p {
      span {
        font-weight: 600;
        color: var(--primaryClr);
        margin-right: 5px;
      }
    }
  }
}
